<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path
      d="
        M16 4a12 12 0 1 0 9.8 19l-1.6-1.3a10 10 0 1 1 0-11.4L25.8 9A12 12
        0 0 0 16 4Zm7.3 7.3L22 12.7l2.3 2.3H12v2h12.2l-2.3 2.3 1.4 1.4 4-4
        .7-.7-.7-.7Z
      "
    />
  </svg>
</template>
