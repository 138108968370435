import type {
  ExtendedOrderItem,
  ExtendedWeighing,
  Order,
  OrderState,
  SignatureRole,
  Unit,
  Weighing,
  OrderDeliveryState,
} from "@/types";
import type { OrderFragment } from "@/types/graphql";
import { isDefinedAndNotNull } from "../filterHelper";
import { buildExtendedWeighing } from "../weighing/weighing.helper";

export const serializeRawOrder = (order: OrderFragment): Order => {
  let address;
  let orderItems;

  if (order.orderItems) {
    orderItems = order.orderItems
      ?.map((orderItem) => {
        let weighings;

        if (!orderItem) return undefined;

        if (orderItem.weighings) {
          weighings = orderItem.weighings.map((weighing) => ({
            id: weighing.id,
            bonNumber: weighing.bonNumber,
            date: weighing.date || undefined,
            signatures: weighing.signatures
              .filter(isDefinedAndNotNull)
              .map((signature) => ({
                id: signature.id,
                imageUrl: signature.imageUrl,
                role: signature.role as SignatureRole,
                description: signature.description || undefined,
              })),
            driver: weighing.driver || undefined,
            customer: weighing.customer || undefined,
            vehicle: weighing.vehicle || undefined,
            weight: weighing.weight,
            unit: weighing.unit as unknown as Unit, // WeightUnit Enum was added, stuff for another PR
          }));
        }

        return {
          id: orderItem.id,
          material: {
            name: orderItem.material.name,
          },
          // TODO: Standardize Units with backend type
          unit: orderItem.unit as unknown as Unit,
          targetWeight: orderItem.targetWeight,
          weightReached: orderItem.weightReached,
          weightPromised: orderItem.weightPromised,
          weighings,
        };
      })
      .filter(isDefinedAndNotNull);
  }

  const deliveryPlaceAddress = order.deliveryPlace?.address;
  if (deliveryPlaceAddress) {
    address = {
      city: deliveryPlaceAddress.city,
      houseNumber: deliveryPlaceAddress.houseNumber || undefined,
      street: deliveryPlaceAddress.street,
      zipCode: deliveryPlaceAddress.zipCode,
    };
  }

  return {
    id: order.id,
    number: order.number,
    state: order.state as OrderState,
    deliveryState: order.deliveryState as OrderDeliveryState,
    customer: {
      name: order.customer?.name,
    },
    vehicle: {
      id: order.vehicle?.id,
      capacityInKg: order.vehicle?.capacityInKg,
    },
    items: orderItems,
    address,
  };
};

export const getExtendedWeighings = (
  weighings: Array<Weighing>,
  order: Order,
  orderItemId: string,
) => {
  const extededWeighings = [] as Array<ExtendedWeighing>;
  weighings.forEach(({ id }) => {
    const extendedWeighing = buildExtendedWeighing(order, orderItemId, id);
    if (extendedWeighing) extededWeighings.push(extendedWeighing);
  });
  return extededWeighings;
};

export const buildExtendedOrder = (
  rawOrder: OrderFragment,
  orderItemId: string,
): ExtendedOrderItem | undefined => {
  const order = serializeRawOrder(rawOrder);
  const orderItem = order.items?.find((item) => item.id === orderItemId);

  if (!orderItem) return undefined;

  return {
    type: "order",
    orderId: order.id,
    orderNumber: order.number,
    id: orderItem.id,
    state: order.state,
    deliveryState: order.deliveryState,
    address: order.address,
    material: orderItem.material,
    customer: order.customer,
    vehicle: order.vehicle,
    targetWeight: orderItem.targetWeight,
    weightReached: orderItem.weightReached,
    weightPromised: orderItem.weightPromised,
    weighingIds: orderItem.weighings
      ? orderItem.weighings.map(({ id }) => id)
      : [],
    unit: orderItem.unit,
  };
};

export const buildExtendedOrders = (order: OrderFragment) =>
  order.orderItems
    ?.map((item) => (item ? buildExtendedOrder(order, item.id) : item))
    .filter(isDefinedAndNotNull);
