<script setup lang="ts">
import { computed, type PropType } from "vue";
import { useI18n } from "vue-i18n";
import { getUniqueId, useFormatUnit, convertUnit } from "@/helpers";
import { DescriptionTag } from "@/components";
import { ScaleStatus, Unit } from "@/types";

const { t } = useI18n();

const props = defineProps({
  targetWeight: {
    type: Number as PropType<number>,
    required: true,
  },
  targetWeightUnit: {
    type: String as PropType<Unit>,
    required: true,
  },
  currentWeight: {
    type: Number as PropType<number>,
    required: true,
  },
  currentWeightUnit: {
    type: String as PropType<Unit>,
    required: true,
  },
  scaleStatus: {
    type: String as PropType<ScaleStatus>,
    default: ScaleStatus.Pending,
  },
});

const { formatUnit } = useFormatUnit();

const WIDTH = 260;
const arcPadding = 10;
const scaleArcId = getUniqueId("scale-arc-");
const scaleGradientId = getUniqueId("scale-gradient-");
const scaleArcStrokeStyle = { stroke: `url(#${scaleGradientId})` };

const height = WIDTH / 2;
const viewBox = `0 0 ${WIDTH} ${height}`;
const arc = `M${arcPadding},${height - arcPadding / 2} A1,1 0 0 1 ${
  WIDTH - arcPadding
},${height - arcPadding / 2}`;
const totalArcLength = (height - arcPadding) * Math.PI;

const progress = computed(() => {
  const convertedCurrentWeight = convertUnit(
    props.currentWeight,
    props.currentWeightUnit,
    props.targetWeightUnit,
  );
  let progressValue = convertedCurrentWeight / props.targetWeight;
  progressValue = Math.max(0, progressValue);
  progressValue = Math.min(1, progressValue);
  return progressValue;
});
const progressArcOffset = computed(() => totalArcLength * (1 - progress.value));
const currentWeightDisplay = computed(() =>
  formatUnit(props.currentWeight, props.currentWeightUnit),
);
const gradientStop = computed(() => `${Math.max(0.1, progress.value) * 100}%`);
</script>

<template>
  <div class="scale-arc">
    <svg ref="scale" :viewBox="viewBox" fill="none" data-testid="scale-svg">
      <defs>
        <path
          :id="scaleArcId"
          :d="arc"
          stroke-width="10"
          stroke-linecap="round"
          data-testid="scale-arc-path"
        />
        <linearGradient :id="scaleGradientId" data-testid="scale-gradient">
          <stop
            offset="0%"
            class="scale-arc--gradient-start"
            :class="`is-${scaleStatus}`"
          />
          <stop
            :offset="gradientStop"
            class="scale-arc--gradient-end"
            :class="`is-${scaleStatus}`"
          />
        </linearGradient>
      </defs>
      <use
        :href="`#${scaleArcId}`"
        :class="`is-${scaleStatus}`"
        class="scale-arc--background"
        data-testid="scale-arc-background"
      />
      <use
        :href="`#${scaleArcId}`"
        :style="scaleArcStrokeStyle"
        :stroke-dasharray="totalArcLength"
        :stroke-dashoffset="progressArcOffset"
        class="scale-arc--progress"
      />
    </svg>
    <div class="scale-arc--description-container">
      <DescriptionTag
        :title="t('scale.currentWeight')"
        :value="currentWeightDisplay"
        :vertical="true"
      />
    </div>
  </div>
</template>

<style>
.scale-arc {
  position: relative;
}

.scale-arc--background {
  &.is-pending {
    stroke: var(--color-background-neutral-shaded);
  }

  &.is-loading {
    stroke: var(--color-background-info-shaded);
  }

  &.is-vehicleReady {
    stroke: var(--color-background-success-shaded);
  }

  &.is-orderReady {
    stroke: var(--color-background-success-shaded);
  }

  &.is-vehicleOverloaded {
    stroke: var(--color-background-error-shaded);
  }

  &.is-orderOverloaded {
    stroke: var(--color-background-error-shaded);
  }
}

.scale-arc--gradient-start {
  stop-opacity: 0.4;
}

.scale-arc--gradient-start,
.scale-arc--gradient-end {
  &.is-pending {
    stop-color: var(--color-neutral);
  }

  &.is-loading {
    stop-color: var(--color-info);
  }

  &.is-vehicleReady {
    stop-color: var(--color-success);
  }

  &.is-orderReady {
    stop-color: var(--color-success);
  }

  &.is-vehicleOverloaded {
    stop-color: var(--color-error);
  }

  &.is-orderOverloaded {
    stop-color: var(--color-error);
  }
}

.scale-arc--description-container {
  position: absolute;
  inset-inline: 0;
  inset-block-end: 0;
}
</style>
