export default {
  heading: "Lieferschein",
  signatureHeading: "Lieferschein Unterschrift",
  detailsHeading: "Lieferschein {value}",
  acceptedFrom: "Lieferschein angenommen von",
  name: "Vor- und Nachname",
  signatureForConfirmation: "Unterschrift zur Lieferbestätigung",
  confirmationPhotos: "Fotos zur Lieferbestätigung",
  retryConfirmationPhotos: "Fotos erneut hochladen",
  errorMessage: {
    name: "Name hinzufügen",
    signature: "Unterschrift hinzufügen",
  },
  validation: {
    oneOfConfirmationPhotoAndSignature:
      "Bitte dokumentieren Sie die Lieferung mit Unterschrift des Kunden oder mindestens einem Foto.",
  },
  continue: "Weiter",
  save: "Speichern",
};
