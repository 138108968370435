<script setup lang="ts">
import { computed, type PropType } from "vue";
import EnglishLogo from "./EnglishLogo.component.vue";
import GermanLogo from "./GermanLogo.component.vue";

const props = defineProps({
  isInverted: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  isCentered: {
    type: Boolean as PropType<boolean>,
    default: true,
  },
});

const classes = computed(() => ({
  "is-inverted": props.isInverted,
  "is-centered": props.isCentered,
}));
</script>

<template>
  <component
    :is="$i18n.locale === 'de' ? GermanLogo : EnglishLogo"
    class="logo"
    :class="classes"
  />
</template>

<style>
.logo {
  display: block;
  block-size: 40px;

  &.is-inverted path {
    fill: var(--color-logo-inverted);
  }

  &.is-centered {
    margin-inline: auto;
  }
}
</style>
