export enum Unit {
  g = "g",
  kg = "kg",
  t = "t",
  pd = "pd",
  lb = "lb",
  st = "st",
  lt = "lt",
  dwt = "dwt",
  oz = "oz",
}
