<template>
  <div class="badge" :class="modifier">
    {{ label }}
  </div>
</template>

<script setup lang="ts">
import { computed, type PropType } from "vue";
import type { BadgeStatus } from "@/types/BadgeStatus";

const props = defineProps({
  label: {
    type: String as PropType<string>,
    required: true,
  },
  status: {
    type: String as PropType<BadgeStatus>,
    required: true,
  },
});

const modifier = computed(() => `is-${props.status}`);
</script>

<style>
.badge {
  display: inline-block;
  border-radius: 2em;
  padding-block: var(--spacing-4);
  padding-inline: var(--spacing-8);

  &.is-success {
    color: var(--color-success);
    background-color: var(--color-background-success);
  }

  &.is-warning {
    color: var(--color-warning);
    background-color: var(--color-background-warning);
  }

  &.is-error {
    color: var(--color-error);
    background-color: var(--color-background-error);
  }

  &.is-info {
    color: var(--color-info);
    background-color: var(--color-background-info);
  }

  &.is-neutral {
    color: var(--color-neutral);
    background-color: var(--color-background-neutral);
  }
}
</style>
