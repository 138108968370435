<template>
  <dl class="description-list">
    <slot />
  </dl>
</template>

<style>
.description-list {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto;
  align-items: center;
  column-gap: var(--spacing-16);
  color: var(--color-text-near-light);
}

.description-list--key {
  display: flex;
  grid-column-start: 1;

  &:not(:first-of-type) {
    margin-block-start: var(--spacing-8);
  }

  &.is-vertical {
    grid-column: span 2;
    grid-row-start: 1;
    margin: auto;
  }
}

.description-list--value {
  grid-column-start: 2;

  .description-list--key + &:not(:first-of-type) {
    margin-block-start: var(--spacing-8);
  }

  &.is-vertical {
    grid-column: span 2;
    grid-row-start: 2;
    margin: auto;
  }
}
</style>
