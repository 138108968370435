import { defineStore, storeToRefs } from "pinia";
import { buildExtendedOrders, isDefinedAndNotNull } from "@/helpers";
import { useGetOrdersQuery } from "@/types/graphql";
import type { ExtendedOrderItem, OrderState } from "@/types";
import { computed, reactive, ref, type Ref } from "vue";
import { useUserStore } from "./user.store";

interface OrdersStore {
  queryString: Ref<string>;
  vehicleId: Ref<string | null>;
  state: Ref<Array<OrderState>>;
  getOrdersQuery: ReturnType<typeof useGetOrdersQuery>;
}

export const useOrdersStore = defineStore("orders", {
  state: (): OrdersStore => {
    const queryString = ref("");
    const vehicleId = ref(null);
    const state = ref([]);
    const variables = reactive({ state, vehicleId, queryString });
    const userStore = useUserStore();
    const { user } = storeToRefs(userStore);
    const getOrdersQuery = useGetOrdersQuery({
      variables,
      pause: computed(() => !user.value),
    });

    return { queryString, vehicleId, state, getOrdersQuery };
  },
  getters: {
    fetching: (state) => state.getOrdersQuery.fetching,
    orders: (state): ExtendedOrderItem[] =>
      state.getOrdersQuery.data?.orders.nodes
        .flatMap((order) => buildExtendedOrders(order))
        .filter(isDefinedAndNotNull) ?? [],
  },
  actions: {
    async fetchOrders(
      state: Array<OrderState>,
      vehicleId: string | null = null,
      queryString = "",
    ) {
      this.queryString = queryString;
      this.vehicleId = vehicleId;
      this.state = state;
    },
  },
});
