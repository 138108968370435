<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { BadgeStatus, IconName, RouteName, ToastStatus } from "@/types";
import { useOrderStore } from "@/stores";
import { useRouter } from "vue-router";
import { ref } from "vue";
import { useToasts } from "@/composables";
import PfrDialog from "../PfrDialog/PfrDialog.component.vue";
import IconBadge from "../IconBadge/IconBadge.component.vue";

const { t } = useI18n();
const router = useRouter();
const orderStore = useOrderStore();
const { unassignOrder } = orderStore;
const dialog = ref<typeof PfrDialog | null>(null);

interface Props {
  orderNumber?: string;
  orderId: string;
}

const props = defineProps<Props>();
const { addToast } = useToasts();

const cancelOrder = async () => {
  const isSuccess = await unassignOrder(props.orderId);

  if (!isSuccess) return;

  addToast(
    t("components.cancelOrder.success", { orderNumber: props.orderNumber }),
    ToastStatus.Success,
  );
  router.push({
    name: RouteName.OpenOrders,
  });
};

const openDialog = () => {
  dialog.value?.openDialog();
};
</script>

<template>
  <pfreundt-button
    variant="text"
    color="primary"
    type="button"
    size="xsmall"
    @click="openDialog()"
  >
    {{ t("navigation.cancel") }}
  </pfreundt-button>

  <PfrDialog
    ref="dialog"
    :accept-label="t('components.cancelOrder.confirm')"
    @accept="cancelOrder()"
  >
    <IconBadge
      :icon="IconName.ExclamationTriangle"
      :status="BadgeStatus.Warning"
    />

    <pfreundt-heading size="xsmall" tag="h2" centered balanced no-margin>
      {{ t("components.cancelOrder.title", { orderNumber }) }}
    </pfreundt-heading>

    <p class="cancel-order--hint">
      {{ t("components.cancelOrder.hint") }}
    </p>
  </PfrDialog>
</template>

<style>
.cancel-order--hint {
  @apply text-16/20;

  text-wrap: balance;
  text-align: center;
}

.cancel-order--title {
  font-weight: 700;
}
</style>
