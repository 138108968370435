<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path
      d="M16 3c-5 0-9 4-9 9 0 1.4.6 3 1.3 4.8l2.8 5.4c2 3.4 4 6.4 4 6.4l.9
        1.1.8-1.1s2-3 4-6.4l2.9-5.4C24.4 15 25 13.4 25 12c0-5-4-9-9-9Zm0 2a7 7 0 0 1
        7 7c0 .8-.4 2.3-1.2 4a55 55 0 0 1-2.7 5.2c-1.5 2.7-2.5 4.1-3.1
        5-.6-.9-1.6-2.3-3.1-5a55 55 0 0 1-2.7-5.2A12 12 0 0 1 9 12a7 7 0 0 1 7-7Zm0
        5a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z
      "
    />
  </svg>
</template>
