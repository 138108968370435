<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { getUniqueId } from "@/helpers";
import { useImageUpload } from "./ImageUpload.composable";

const props = defineProps({
  fieldName: {
    type: String,
    required: true,
  },
  isRequired: {
    type: Boolean,
    default: false,
  },
});

const {
  images,
  onFileChange,
  handleBlur,
  errorMessage,
  deleteImage,
  onImageLoad,
} = useImageUpload(props.fieldName);

const { t } = useI18n();

const imageUploadId = getUniqueId("a11y-image-upload-");
</script>

<template>
  <ul v-if="images.length > 0" class="preview-images" role="list">
    <li v-for="(image, index) in images" :key="index">
      <a
        :href="image.url"
        target="_blank"
        rel="noreferrer"
        class="preview-images--link"
      >
        <img
          :src="image.url"
          class="preview-images--img"
          :alt="t('components.imageUpload.photoPreview')"
          width="98px"
          @load="onImageLoad"
        />

        <div class="preview-images--delete">
          <pfreundt-button
            :aria-label="t('components.imageUpload.deletePreviewPhoto')"
            color="monochrome"
            size="xsmall"
            variant="filled"
            icon-only
            icon-before="delete"
            @click.stop.prevent="deleteImage(image.url)"
          >
          </pfreundt-button>
        </div>
      </a>
    </li>
  </ul>

  <label
    class="pfreundt-button"
    data-color="monochrome"
    data-size="medium"
    data-variant="filled"
    data-stretched
    :for="imageUploadId"
  >
    <pfreundt-icon name="photo_camera" variant="outlined" />

    <span class="truncate">
      {{ t("components.imageUpload.takePhoto") }}
      {{ isRequired ? "" : `(${$t("forms.optional")})` }}
    </span>
  </label>

  <span v-if="errorMessage" class="image-upload--error">
    {{ errorMessage }}
  </span>

  <input
    :id="imageUploadId"
    accept="image/*"
    class="file-input sr-only"
    :field-name="fieldName"
    type="file"
    multiple
    @change="onFileChange"
    @blur="handleBlur"
  />
</template>

<style>
@import "photoswipe/style.css";

.preview-images {
  --preview-image-size: 98px;
  --preview-image-aspect-ratio: 16 / 9;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: var(--spacing-16);
  margin-block-end: var(--spacing-16);
}

.preview-images--link {
  /* Use display: block; since safari has problems with inline-block; */
  display: block;
  position: relative;
}

.preview-images--img {
  inline-size: var(--preview-image-size);
  aspect-ratio: var(--preview-image-aspect-ratio);
  object-fit: contain;
  border: 1px solid var(--color-border-neutral-medium);
  border-radius: var(--spacing-4);
}

.preview-images--delete {
  position: absolute;
  inset-block-start: var(--spacing-4);
  inset-inline-end: var(--spacing-4);
}

.image-upload--error {
  @apply text-14/24;

  display: inline-block;
  margin-block-start: var(--spacing-4);
  color: var(--color-error);
}
</style>
