import mixpanel from "mixpanel-browser";

export const initializeMixpanelPlugin = () => {
  mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN, {
    ignore_dnt: true,
    api_host: "https://api-eu.mixpanel.com",
  });

  const mixpanelVuePlugin = {
    install: (app: any) => {
      app.provide("mixpanel", mixpanel);
    },
  };
  return mixpanelVuePlugin;
};
