import { defineStore, storeToRefs } from "pinia";
import {
  buildBlanketOrder,
  buildExtendedOrders,
  isDefinedAndNotNull,
} from "@/helpers";
import type { BlanketOrder, ExtendedOrderItem } from "@/types";
import { useGetCombinedOrdersQuery } from "@/types/graphql";
import { computed, reactive, ref, type Ref } from "vue";
import { useUserStore } from "./user.store";

interface CombinedOrdersStore {
  queryString: Ref<string>;
  getCombinedOrdersQuery: ReturnType<typeof useGetCombinedOrdersQuery>;
}

export const useCombinedOrdersStore = defineStore("combinedOrders", {
  state: (): CombinedOrdersStore => {
    const queryString = ref("");
    const variables = reactive({ queryString });
    const userStore = useUserStore();
    const { user } = storeToRefs(userStore);
    const getCombinedOrdersQuery = useGetCombinedOrdersQuery({
      variables,
      pause: computed(() => !user.value),
    });

    return {
      queryString,
      getCombinedOrdersQuery,
    };
  },
  getters: {
    fetching: (state) => state.getCombinedOrdersQuery.fetching,
    combinedOrders: (state): Array<BlanketOrder | ExtendedOrderItem> => {
      if (!state.getCombinedOrdersQuery.data?.loadAndGoOrders?.nodes) return [];
      const combinedOrdersArray =
        state.getCombinedOrdersQuery.data.loadAndGoOrders.nodes.map(
          (combinedOrder) => {
            if (combinedOrder.orderType === "blanket_order") {
              return buildBlanketOrder(combinedOrder.blanketOrder!);
            }
            return buildExtendedOrders(combinedOrder.order!);
          },
        );
      return combinedOrdersArray.flat().filter(isDefinedAndNotNull);
    },
  },
  actions: {
    async fetchCombinedOrders(queryString = "") {
      this.queryString = queryString;
    },
  },
});
