<script setup lang="ts">
import { onUnmounted, ref, watch, type PropType } from "vue";
import { useField } from "vee-validate";
import { IconName, IconSize } from "@/types";
import Icon from "@/components/generic/Icon/Icon.component.vue";
import { useSignatureField } from "./useSignatureField.composable";

const props = defineProps({
  fieldName: {
    type: String as PropType<string>,
    required: true,
  },
  backgroundColor: {
    type: String as PropType<string>,
    default: undefined,
  },
});

// eslint-disable-next-line vue/no-setup-props-destructure
const { value, handleChange, resetField } = useField<Blob>(
  props.fieldName,
  undefined,
  { validateOnValueUpdate: false },
);

const canvasElement = ref<HTMLCanvasElement>();
// eslint-disable-next-line vue/no-setup-props-destructure
const { disconnect, reset, isClear } = useSignatureField(
  value,
  handleChange,
  canvasElement,
  { backgroundColor: props.backgroundColor },
);

watch(isClear, (newVal) => newVal && resetField());

onUnmounted(() => {
  disconnect();
});
</script>

<template>
  <div class="signature-field" data-testid="signature">
    <div v-if="isClear" class="signature-field--icon">
      <Icon :icon="IconName.Pen" :size="120" />
    </div>
    <canvas ref="canvasElement" class="signature-field--canvas" />
    <div v-if="!isClear" class="signature-field--clear">
      <button
        class="signature-field--clear-button"
        type="button"
        @click="reset"
      >
        <Icon :icon="IconName.Trash" :size="IconSize.Small" />
        {{ $t("components.signature.delete") }}
      </button>
    </div>
  </div>
</template>

<style>
.signature-field {
  position: relative;
  inline-size: 100%;
  block-size: 100%;
  border-radius: 4px;
  box-shadow: var(--elevation-13);
  background-color: var(--color-background-light);
}

.signature-field--icon {
  position: absolute;
  inset-block-start: 50%;
  inset-inline-start: 50%;
  transform: translate(-50%, -50%);
  color: var(--color-background-neutral-shaded);
  pointer-events: none;
}

.signature-field--canvas {
  display: block;
  inline-size: 100%;
  block-size: 100%;
}

.signature-field--clear {
  position: absolute;
  inset-block-start: var(--spacing-8);
  inset-inline-start: 50%;
  transform: translateX(-50%);
  color: var(--color-text-near-light);

  &:focus,
  &:hover,
  &:active {
    text-decoration: underline;
  }

  .signature-field--clear-button {
    @apply text-16/24;

    display: flex;
    align-items: center;
    gap: var(--spacing-4);
    color: var(--color-text-near-light);
  }
}
</style>
