import { storeToRefs } from "pinia";
import { watchDebounced } from "@vueuse/core";
import { useOrderStore } from "@/stores";
import { useOrderItemSubscription } from "@/types/graphql";

export const useOrderItem = (orderItemId: string) => {
  const store = useOrderStore();
  const { data, error } = useOrderItemSubscription({
    variables: { id: orderItemId },
  });

  watchDebounced(
    data,
    (newData) => {
      if (!newData) return;
      const { orderItem } = newData;
      store.updateOrderItem({
        ...orderItem,
        weighingIds: orderItem.weighings
          ? orderItem.weighings.map(({ id }) => id)
          : [],
      });
    },
    { debounce: 500 },
  );

  return {
    ...storeToRefs(store),
    error,
    fetchOrderItem: store.fetchOrder,
  };
};
