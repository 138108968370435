<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
    <path
      d="M38.8 5.1A24 24 0 0 0 9.2 42.9l592 464a24 24 0 0 0
    29.6-37.8l-267-209.3a247 247 0 0 1 123.8 61.4c9.8 9 24.9 8.3
    33.9-1.5s8.3-24.9-1.5-33.9a294.8 294.8 0 0 0-221.4-77L169.4 107.5A422.9
    422.9 0 0 1 320 80a422.2 422.2 0 0 1 280.1 105.7c9.9 8.8 25.1 7.8
    33.9-2.2s7.8-25.1-2.2-33.9A470.2 470.2 0 0 0 320 32a471.9 471.9 0 0 0-193.9
    41.5L38.8 5.1zm38.1 151.5-39.2-30.9c-10.2 7.6-20 15.6-29.5 23.9a24 24 0 0 0
    31.7 36.1 415.2 415.2 0 0 1 37-29.1zm149.4 117.7L184 241a296.1 296.1 0 0
    0-64.1 44.8 24 24 0 0 0 32.4 35.4 249 249 0 0 1 73.9-46.9zM376 424a56 56 0
    1 0-112 0 56 56 0 1 0 112 0z"
    />
  </svg>
</template>
