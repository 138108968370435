export default {
  heading: "Delivery Note",
  signatureHeading: "Signature Delivery Note",
  detailsHeading: "Delivery Note {value}",
  acceptedFrom: "Delivery Note accepted from",
  name: "First and Last Name",
  signatureForConfirmation: "Confirmation signature",
  confirmationPhotos: "Confirmation photos",
  retryConfirmationPhotos: "Re-upload confirmation photos",
  errorMessage: {
    name: "Add name",
    signature: "Add signature",
  },
  validation: {
    oneOfConfirmationPhotoAndSignature:
      "Please upload either a signature or a photo to confirm delivery.",
  },
  continue: "Continue",
  save: "Save",
};
