<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path
      d="
        M1 4v21h3.2A4 4 0 0 0 8 28a4 4 0 0 0 3.8-3h8.4a4 4 0 0 0 3.8 3 4 4 0 0
        0 3.8-3H31V14.6l-.3-.3-6-6-.3-.3H19V4Zm2 2h14v17h-5.2A4 4 0 0 0 8 20a4
        4 0 0 0-3.8 3H3Zm16 4h4.6l5.4 5.4V23h-1.2a4 4 0 0 0-3.8-3 4 4 0 0 0-3.8
        3H19ZM8 22a2 2 0 0 1 2 2 2 2 0 0 1-2 2 2 2 0 0 1-2-2c0-1.1.9-2 2-2Zm16
        0a2 2 0 0 1 2 2 2 2 0 0 1-2 2 2 2 0 0 1-2-2c0-1.1.9-2 2-2Z
      "
    />
  </svg>
</template>
