<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path
      d="
        M15 4a2 2 0 0 0-1.4.6A2 2 0 0 0 13 6v1H7v2h1v16a3 3 0 0 0 3 3h12a3 3 0
        0 0 3-3V9h1V7h-6V6c0-.5-.2-1-.6-1.4A2 2 0 0 0 19 4Zm0 2h4v1h-4Zm-5
        3h14v16c0 .6-.4 1-1 1H11a1 1 0 0 1-1-1Zm2 3v11h2V12Zm4 0v11h2V12Zm4
        0v11h2V12Z
      "
    />
  </svg>
</template>
