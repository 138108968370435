import { useUserStore, useDriversStore, useVehiclesStore } from "@/stores";
import type { Driver, Vehicle } from "@/types/graphql";
import { storeToRefs } from "pinia";
import { onMounted } from "vue";

export function useCheckDriverAndVehicleId() {
  const userStore = useUserStore();
  const driversStore = useDriversStore();
  const vehiclesStore = useVehiclesStore();

  const { fetchDrivers } = driversStore;
  const { fetchVehicles } = vehiclesStore;
  const { checkDriverId, checkVehicleId } = userStore;

  const { drivers } = storeToRefs(driversStore);
  const { vehicles } = storeToRefs(vehiclesStore);

  driversStore.$onAction(({ name, after }) => {
    after(() => {
      if (name !== "fetchDrivers") return;

      checkDriverId(drivers.value as Driver[]);
    });
  });

  vehiclesStore.$onAction(({ name, after }) => {
    after(() => {
      if (name !== "fetchVehicles") return;

      checkVehicleId(vehicles.value as Vehicle[]);
    });
  });

  onMounted(() => {
    fetchDrivers();
    fetchVehicles();
  });
}
