<script setup lang="ts">
import { type PropType, onUnmounted } from "vue";
import {
  Icon,
  PfrFooter,
  Search,
  Sidebar,
  TitleHeader,
  Toasts,
} from "@/components";
import { IconName } from "@/types";
import { useI18n } from "vue-i18n";
import { watchDebounced } from "@vueuse/core";
import { useSearch } from "@/composables";

defineProps({
  hideHeader: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  heading: {
    type: String as PropType<string>,
    default: undefined,
  },
});

const { t } = useI18n();
const { searchOpen, openSearch, closeSearch, searchValue } = useSearch();

const emit = defineEmits(["search"]);

onUnmounted(() => {
  const bodyElement = document.querySelector("body");

  if (bodyElement) {
    bodyElement.style.removeProperty("overflow");
  }
});

watchDebounced(
  searchValue,
  () => {
    emit("search", searchValue.value);
  },
  { debounce: 250, maxWait: 400 },
);
</script>

<template>
  <div class="page-layout" data-testid="page-layout">
    <header v-if="!hideHeader" class="page-layout--header">
      <slot name="header">
        <Transition name="slide-fade" mode="out-in">
          <TitleHeader v-if="!searchOpen && heading" :heading="heading">
            <template #left>
              <Sidebar />
            </template>

            <template #right>
              <button
                :aria-label="t('components.search.label')"
                @click="openSearch"
              >
                <Icon :icon="IconName.Search" />
              </button>
            </template>
          </TitleHeader>

          <Search v-else v-model="searchValue">
            <pfreundt-button
              variant="text"
              color="primary"
              size="xsmall"
              @click="closeSearch"
            >
              {{ t("components.search.cancel") }}
            </pfreundt-button>
          </Search>
        </Transition>
      </slot>
    </header>

    <main class="page-layout--main">
      <div class="page-layout--main-content">
        <slot name="main" />
      </div>
      <PfrFooter>
        <slot name="mainFooter" />
      </PfrFooter>
      <div class="page-layout--toasts">
        <Toasts />
      </div>
    </main>
  </div>
</template>

<style>
.page-layout {
  display: flex;
  flex-direction: column;
  min-block-size: 100dvh;
}

.page-layout--header {
  position: sticky;
  z-index: var(--z-index-default);
  inset-block-start: 0;
  padding-block: var(--spacing-8);
  padding-inline: var(--boundary-box-padding);
  box-shadow: var(--elevation-5);
  background-color: var(--color-background-light);
}

.page-layout--main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  gap: var(--spacing-16);
  position: relative;
  inline-size: calc(
    min(100% - (var(--boundary-box-padding) * 2), var(--boundary-box-width))
  );
  margin-inline: auto;
  padding-block: var(--spacing-24);
}

.page-layout--main-content {
  flex: 1;
}

.page-layout--toasts {
  --size-toast-notification: 100%;

  position: absolute;
  z-index: var(--z-index-default);
  inset-inline: 50%;
  inset-block-start: var(--spacing-12);
  inline-size: 100%;
  transform: translateX(-50%);
}

.slide-fade-enter-active {
  transition: all 0.2s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  opacity: 0;
  transform: translateX(20px);
}
</style>
