<script setup lang="ts">
import { type PropType, computed, ref } from "vue";
import { useField } from "vee-validate";
import { getUniqueId } from "@/helpers";

const props = defineProps({
  id: {
    type: String as PropType<string>,
    default: () => getUniqueId(),
  },
  fieldName: {
    type: String as PropType<string>,
    required: true,
  },
  label: {
    type: String as PropType<string>,
    required: true,
  },
  type: {
    type: String as PropType<HTMLInputElement["type"]>,
    default: "text",
  },
  isRequired: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  hiddenLabel: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  hasError: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  placeholder: {
    type: String as PropType<string>,
    default: "",
  },
  modelValue: {
    type: String as PropType<string>,
    default: "",
  },
  syncVModel: {
    type: Boolean as PropType<boolean>,
    default: undefined,
  },
});

const {
  value: modelValue,
  errorMessage,
  meta,
  handleChange,
} = useField<string>(() => props.fieldName, undefined, {
  validateOnValueUpdate: false,
  syncVModel: props.syncVModel,
});

const input = ref<HTMLInputElement | null>(null);

const validationListeners = computed(() => {
  // If the field is valid or have not been validated yet
  // lazy
  if (!errorMessage.value) {
    return {
      blur: handleChange,
      change: handleChange,
      // disable `shouldValidate` to avoid validating on input
      input: (e: Event) => handleChange(e, false),
    };
  }
  // Aggressive
  return {
    blur: handleChange,
    change: handleChange,
    input: handleChange,
  };
});

const hasError = computed(() => props.hasError || (!meta.valid && meta.dirty));

defineExpose({ input });
</script>

<template>
  <div class="form--group">
    <label
      :for="id"
      class="form--group-label"
      :class="hiddenLabel && 'sr-only'"
    >
      {{ label }}
      <span v-if="!isRequired">({{ $t("forms.optional") }})</span>
    </label>
    <div :class="['field', { 'has-error': hasError }]" data-testid="field">
      <span v-if="$slots.iconBefore" class="field--icon">
        <slot name="iconBefore" />
      </span>

      <input
        :id="id"
        ref="input"
        :placeholder="placeholder"
        :v-model="modelValue"
        :type="type"
        class="field--input"
        v-on="validationListeners"
      />
    </div>
    <span v-if="hasError" class="form--group-error" aria-live="assertive">
      {{ errorMessage }}
    </span>
  </div>
</template>

<style>
/* Additions to the zweitag whitelabel field component */
.field:has(.field--icon) {
  --icon-spacing: var(--spacing-12);
  --icon-size: var(--spacing-24);

  display: flex;
  align-items: center;
  position: relative;

  /* Increase specifity by wrapping to overwrite whitelabel components */

  .field--icon {
    position: absolute;
    inset-inline-start: var(--spacing-8);
  }

  /* stylelint-disable-next-line selector-max-combinators */
  .field--icon + .field--input {
    border-radius: 4px;
    padding-inline-start: calc(var(--icon-spacing) + var(--icon-size));
  }
}
</style>
