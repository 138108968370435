export enum ButtonElement {
  Button = "button",
  Link = "a",
  RouterLink = "router-link",
}

export enum ButtonSize {
  Small = "small",
  Medium = "medium",
  Large = "large",
}

export enum ButtonVariant {
  Primary = "primary",
  Secondary = "secondary",
  Plain = "plain",
}
