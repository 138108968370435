export default {
  heading: "Auftrag {id}",
  blanketOrderHeading: "Rahmenauftrag {id}",
  hint: "{count} Auftrag | {count} Aufträge",
  status: {
    inLoading: "In Beladung",
    ownOrder: "Mein Auftrag",
    paused: "Pausiert",
  },
  card: {
    details: {
      address: "Adresse",
      material: "Material",
      customer: "Kunde",
      total: "Summe",
      signed: "Bereits unterschrieben",
    },
    delivery: {
      distance: "Entfernung",
      infos: "Lieferinfos",
      address: "Adresse",
      deliveryTill: "Lieferung bis {date}",
      date: "Lieferdatum",
      driver: "Fahrer",
      licencePlate: "Kennzeichen",
    },
    openDeliveryNote: "Lieferscheine öffnen",
  },
};
