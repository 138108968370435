import { createApp } from "vue";
import App from "@/App.vue";
import { router } from "@/setup/router";
import { buildI18n } from "@/setup/i18n";
import { store } from "@/setup/store";
import { setupValidation } from "@/setup/validation";
import { plausible } from "@/setup/plausible";
import { hotjar } from "@/setup/hotjar";
import { setupSentry } from "@/setup/sentry";
import { setupDesignSystem } from "@/setup/design-system";
import { initializeMixpanelPlugin } from "./helpers";
import "@/stylesheets/index.css";

const app = createApp(App);

const i18n = buildI18n();

if (import.meta.env.VITE_PLAUSIBLE_DOMAIN) {
  app.use(plausible);
}

if (import.meta.env.VITE_HOTJAR_ID) {
  app.use(hotjar);
}

app.use(initializeMixpanelPlugin());

app.use(store);
app.use(router);
setupSentry(app);
app.use(i18n);
setupValidation(i18n);

setupDesignSystem();

app.mount("#app");
