import { type Ref, watch } from "vue";
import { useRoute, useRouter, type LocationAsRelativeRaw } from "vue-router";
import {
  RouteName,
  type ExtendedWeighing,
  type ExtendedOrderItem,
  OrderDeliveryState,
} from "@/types";
import {
  isDriverSignatureMissing,
  isCustomerSignatureMissing,
} from "@/helpers";

const createRouteLocation = (
  routeName: RouteName,
  orderId: string,
  orderItemId: string,
  weighingId?: string,
) => ({
  name: routeName,
  params: {
    orderId,
    itemId: orderItemId,
    weighingId,
  },
});

interface OrderStep {
  type: "loading" | "weighingConfirm" | "deliveryConfirm" | "closed";
  route: LocationAsRelativeRaw;
}

const getNextOrderStepViaWeighing = (
  orderId: string,
  orderItemId: string,
  weighings: ExtendedWeighing[],
  weighingId?: string,
): OrderStep => {
  const createRoute = (routeName: RouteName, selectedWeighingId?: string) =>
    createRouteLocation(routeName, orderId, orderItemId, selectedWeighingId);

  const weighing = weighings.find((w) => w.id === weighingId) || weighings[0];

  if (isDriverSignatureMissing(weighing)) {
    return {
      type: "weighingConfirm",
      route: createRoute(RouteName.WeighingConfirmation, weighing.id),
    };
  }

  if (isCustomerSignatureMissing(weighing)) {
    return {
      type: "deliveryConfirm",
      route: createRoute(RouteName.DeliveryConfirm, weighing.id),
    };
  }

  return {
    type: "closed",
    route: createRoute(RouteName.ClosedDeliveryNote, weighing.id),
  };
};

const getNextOrderStepViaOrder = (
  order: ExtendedOrderItem,
): OrderStep | null => {
  if (order.deliveryState === OrderDeliveryState.OnSite) {
    return {
      type: "loading",
      route: createRouteLocation(RouteName.Loading, order.orderId, order.id),
    };
  }

  return null;
};

const useRedirectIfNeeded = () => {
  const route = useRoute();
  const router = useRouter();

  const redirect = (routeLocation: LocationAsRelativeRaw) => {
    if (route.name !== routeLocation.name) router.push(routeLocation);
  };

  return {
    redirect,
  };
};

export const useWeighingRedirectToStep = (
  weighing: Ref<ExtendedWeighing | null>,
) => {
  const { redirect } = useRedirectIfNeeded();
  watch(weighing, () => {
    if (!weighing.value) return;

    const nextStep = getNextOrderStepViaWeighing(
      weighing.value.orderId,
      weighing.value.orderItemId,
      [weighing.value],
      weighing.value.id,
    );

    redirect(nextStep.route);
  });
};

export const useOrderRedirectToStep = (
  order: Ref<ExtendedOrderItem | null>,
) => {
  const { redirect } = useRedirectIfNeeded();
  watch(order, () => {
    if (!order.value) return;
    if (order.value.weighingIds.length > 0) return;

    const nextStep = getNextOrderStepViaOrder(order.value);
    if (!nextStep) return;

    redirect(nextStep.route);
  });
};
