<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path
      d="
        M16 4A12 12 0 0 0 6 9.3V6H4v7h7v-2H7.4A10 10 0 0 1 26 16a10 10 0 1
        1-20 0H4A12 12 0 1 0 16 4Zm-1 4v9h7v-2h-5V8Z
      "
    />
  </svg>
</template>
