<script setup lang="ts">
import { computed, type PropType } from "vue";
import { IconName, icons, IconSize } from "@/types";

const props = defineProps({
  icon: {
    type: String as PropType<IconName>,
    required: true,
  },
  size: {
    type: Number as PropType<number>,
    default: IconSize.Medium,
  },
  isInline: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  isBold: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
});

const style = computed(
  () => `inline-size: ${props.size}px; block-size: ${props.size}px;`,
);

const iconComponent = computed(() => {
  if (props.icon in icons) {
    return icons[props.icon];
  }
  throw new Error(`${props.icon} is not a valid icon identifier.`);
});
</script>

<template>
  <component
    :is="iconComponent"
    :style="style"
    :class="['icon', { 'is-inline': isInline }, { 'is-bold': isBold }]"
    :data-testid="`${icon}-icon`"
  />
</template>

<style>
.icon {
  display: block;
  fill: currentcolor;

  &.is-bold {
    stroke: currentcolor;
  }

  &.is-inline {
    display: inline;
  }
}
</style>
