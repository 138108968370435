<script setup lang="ts">
import { type PropType, computed, onMounted } from "vue";
import { useField } from "vee-validate";
import { getUniqueId } from "@/helpers";

const props = defineProps({
  id: {
    type: String as PropType<string>,
    default: () => getUniqueId(),
  },
  fieldName: {
    type: String as PropType<string>,
    required: true,
  },
  label: {
    type: String as PropType<string>,
    required: true,
  },
  isRequired: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  hasError: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  hiddenLabel: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  inverted: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  options: {
    type: Array as PropType<{ label: string; value?: string | undefined }[]>,
    required: true,
  },
  value: {
    type: String as PropType<string | null>,
    default: "",
  },
  modelValue: {
    type: String as PropType<string | null>,
    default: "",
  },
  syncVModel: {
    type: Boolean as PropType<boolean>,
    default: undefined,
  },
});

const {
  errorMessage,
  meta,
  handleChange,
  setValue,
  value: modelValue,
} = useField<string>(props.fieldName, undefined, {
  validateOnValueUpdate: false,
  syncVModel: props.syncVModel,
});

onMounted(() => {
  setValue(props.value || "");
});

const validationListeners = computed(() => {
  // If the field is valid or has not been validated yet
  // lazy
  if (!errorMessage.value) {
    return {
      blur: handleChange,
      change: handleChange,
    };
  }
  // Aggressive
  return {
    blur: handleChange,
    change: handleChange,
  };
});

const hasError = computed(() => props.hasError || (!meta.valid && meta.dirty));
</script>

<template>
  <div class="form--group">
    <label
      :for="id"
      class="form--group-label"
      :class="hiddenLabel ? 'sr-only' : null"
    >
      {{ label }}
      <span v-if="!isRequired">({{ $t("forms.optional") }})</span>
    </label>

    <div
      :class="['select', { 'has-error': hasError }]"
      data-testid="select"
      :data-inverted="inverted ? '' : undefined"
    >
      <select
        :id="id"
        v-model="modelValue"
        class="select--input"
        v-on="validationListeners"
      >
        <option v-if="value === ''" disabled selected value>
          {{ $t("forms.select.default") }}
        </option>

        <option
          v-for="(option, index) in options"
          :key="index"
          :value="option.value"
        >
          {{ option.label }}
        </option>
      </select>
    </div>
    <span v-if="hasError" class="form--group-error" aria-live="assertive">
      {{ errorMessage }}
    </span>
  </div>
</template>
