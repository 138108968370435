export default {
  optional: "optional",
  validations: {
    required: "Pflichtfeld",
    maxFiles: "Maximale Anzahl von {limit} Fotos erreicht",
  },
  error: "Es ist leider etwas schief gelaufen.",
  select: {
    default: "-- Bitte auswählen --",
  },
};
