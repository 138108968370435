export default {
  optional: "optional",
  validations: {
    required: "Required field",
    maxFiles: "Maximum number of {limit} photos reached.",
  },
  error: "Something went wrong.",
  select: {
    default: "-- Select an option --",
  },
};
