import { defineStore } from "pinia";
import { CombinedError } from "urql";
import { ref, type Ref } from "vue";
import {
  useGetBlanketOrderQuery,
  useCreateOrderFromBlanketOrderMutation,
} from "@/types/graphql";
import type { BlanketOrder, BlanketOrderItem, Unit } from "@/types";
import { buildBlanketOrder } from "@/helpers";
import { buildError } from "@/helpers/error/error.helper";

interface BlanketOrderStore {
  blanketOrder: BlanketOrder | null;
  fetching: boolean;
  error: Ref<CombinedError | undefined>;
  createOrderFromBlanketOrderMutation: ReturnType<
    typeof useCreateOrderFromBlanketOrderMutation
  >;
  currentOrder?: ReducedOrder | null;
}

interface ReducedOrder {
  id: string;
  orderItems: Array<{
    id: string;
  }>;
}

export const useBlanketOrderStore = defineStore("blanketOrder", {
  state: (): BlanketOrderStore => ({
    blanketOrder: null,
    fetching: false,
    error: ref(undefined),
    createOrderFromBlanketOrderMutation:
      useCreateOrderFromBlanketOrderMutation(),
    currentOrder: null,
  }),
  getters: {
    currentOrderId(): string | undefined {
      return this.currentOrder?.id;
    },
    currentOrderItemId(): string | undefined {
      return this.currentOrder?.orderItems[0].id;
    },
    blanketOrderItems(): BlanketOrderItem[] {
      return this.blanketOrder?.blanketOrderItems ?? [];
    },
    materialOptions(): Array<{ label: string; value?: string }> {
      return this.blanketOrderItems.map((bOI: BlanketOrderItem) => ({
        label: bOI.material.name,
        value: bOI.material.id,
      }));
    },
  },
  actions: {
    async fetchBlanketOrder(blanketOrderId: string) {
      this.error = undefined;
      this.fetching = true;
      const { data, error: originalError } = await useGetBlanketOrderQuery({
        variables: { id: blanketOrderId },
      });

      this.fetching = false;
      this.error = originalError.value;

      if (!data.value?.blanketOrder) {
        if (!this.error) {
          this.error = new CombinedError({
            graphQLErrors: ["No Blanket Order given"],
          });
        }
        return;
      }

      const { blanketOrder } = data.value;

      if (!blanketOrder) return;

      this.blanketOrder = buildBlanketOrder(blanketOrder);
    },

    async createOrderFromBlanketOrder(
      blanketOrderId: string,
      materialId: string,
      targetWeight: number,
      unit: Unit,
      vehicleId: string | null,
      driverId: string | null,
    ) {
      this.error = undefined;
      this.fetching = true;

      const variables = {
        blanketOrderId,
        materialId,
        targetWeight,
        unit,
        vehicleId,
        driverId,
      };

      const response =
        await this.createOrderFromBlanketOrderMutation.executeMutation(
          variables,
        );

      this.fetching = false;

      const graphQLErrors = response.data?.createOrderFromBlanketOrder?.errors;

      this.error = buildError(graphQLErrors, response.error);
      if (this.error) return;

      this.currentOrder = response.data?.createOrderFromBlanketOrder.order;
    },
  },
});
