import { fallback, useFormatUnit } from "@/helpers";
import {
  type BlanketOrder,
  type FormattedOrderItem,
  type ExtendedOrderItem,
  OrderState,
  Unit,
} from "@/types";
import { computed } from "vue";

const getOrderItemWeightAccessor = (entity: ExtendedOrderItem) =>
  entity.state === OrderState.Closed ? "weightReached" : "targetWeight";

export const useFormattedOrderItem = (
  item: ExtendedOrderItem | BlanketOrder,
  showTotalWeight?: boolean,
  { formatUnit } = useFormatUnit(),
) => {
  const buildMaterial = (name: string, unit: Unit, weight?: number) =>
    `${
      weight && !showTotalWeight ? formatUnit(weight, unit) : ""
    } ${name}`.trim();

  const number = computed(() => {
    if (item.type === "order") {
      return item.orderNumber;
    }

    return item.number;
  });

  const formattedMaterials = computed(() => {
    if (item.type === "order" && item.material) {
      const weightAccessor = getOrderItemWeightAccessor(item);
      const weight = item[weightAccessor];
      return [buildMaterial(item.material.name, item.unit, weight)];
    }

    if (item.type === "blanketOrder" && item.blanketOrderItems) {
      return item.blanketOrderItems.map((blanketOrderItem) =>
        buildMaterial(
          blanketOrderItem.material.name,
          blanketOrderItem.unit,
          blanketOrderItem.remainingWeight || undefined,
        ),
      );
    }

    return [fallback(undefined)];
  });

  const formattedCustomer = computed(() => fallback(item.customer?.name));

  const formattedOrderItem = computed<FormattedOrderItem>(() => ({
    type: item.type,
    number: number.value,
    customer: formattedCustomer.value,
    materialInfos: formattedMaterials.value,
  }));

  return { formattedOrderItem };
};
