export interface Signature {
  id: string;
  imageUrl: string;
  role: SignatureRole;
  description?: string;
}

export enum SignatureRole {
  Driver = "driver",
  Customer = "customer",
}
