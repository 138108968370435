<template>
  <div class="loading-spinner" />
</template>

<style>
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  --loading-spinner-size: 55px;
  --loading-spinner-speed: 1000ms;

  inline-size: var(--loading-spinner-size);
  block-size: var(--loading-spinner-size);
  border: 4px solid var(--color-background-mid-light);
  border-radius: 50%;
  border-inline-start-color: var(--color-primary);
  animation: rotate var(--loading-spinner-speed) linear infinite;
}
</style>
