export default {
  changeData: "Change data",
  closedOrders: "@:views.closedOrders.heading",
  cookies: "Cookies",
  imprint: "Imprint",
  imprintLink: "https://www.pfreundt.de/en/imprint",
  licensePlate: "License Plate",
  linkSharing: "Link sharing",
  logout: "Logout",
  menu: "Open menu",
  myOrders: "@:views.myOrders.heading",
  openOrders: "@:views.openOrders.heading",
  privacy: "Privacy",
  privacyLink: "https://www.pfreundt.de/en/privacy-policy",
  userName: "Username",
};
