import { parseISO } from "date-fns";
import { type ExtendedWeighing, type Order, SignatureRole } from "@/types";

export const buildExtendedWeighing = (
  order: Order,
  orderItemId: string,
  weighingId: string,
): ExtendedWeighing | undefined => {
  const orderItem = order.items?.find((item) => item.id === orderItemId);

  if (!orderItem) return undefined;

  const selectedWeighing = orderItem.weighings?.find(
    (weighing) => weighing.id === weighingId,
  );

  if (!selectedWeighing) return undefined;

  return {
    type: "weighing",
    id: selectedWeighing.id,
    bonNumber: selectedWeighing.bonNumber,
    orderItemId: orderItem.id,
    orderId: order.id,
    orderNumber: order.number,
    date: selectedWeighing.date ? parseISO(selectedWeighing.date) : undefined,
    weight: selectedWeighing.weight,
    targetWeight: orderItem.targetWeight,
    targetWeightUnit: orderItem.unit,
    unit: selectedWeighing.unit,
    material: orderItem.material,
    customer: order.customer,
    address: order.address,
    signatures: selectedWeighing.signatures,
    driver: selectedWeighing.driver,
    vehicle: selectedWeighing.vehicle,
  };
};

// eslint-disable-next-line max-len
const isSignatureMissing =
  (role: SignatureRole) => (weighing: ExtendedWeighing) =>
    !weighing.signatures.some((signature) => signature.role === role);

export const isDriverSignatureMissing = isSignatureMissing(
  SignatureRole.Driver,
);
export const isCustomerSignatureMissing = isSignatureMissing(
  SignatureRole.Customer,
);
export const isAnySignatureMissing = (weighing: ExtendedWeighing) =>
  isDriverSignatureMissing(weighing) || isCustomerSignatureMissing(weighing);
