import type { Toast, ToastStatus } from "@/types";
import { nextTick, ref } from "vue";

const toasts = ref<Toast[]>([]);
const toastIdCounter = ref(0);

export const useToasts = () => {
  const removeToast = (id: number) => {
    const index = toasts.value.findIndex((toast) => toast.id === id);
    if (index !== -1) {
      toasts.value.splice(index, 1);
    }
  };

  const addToast = (message: string, status: ToastStatus) => {
    toastIdCounter.value += 1;
    const id = toastIdCounter.value;

    toasts.value.push({ id, message, status, visible: true });
    setTimeout(() => {
      const toast = toasts.value.find((t) => t.id === id);
      if (toast) {
        toast.visible = false;
        nextTick(() => {
          removeToast(id);
        });
      }
    }, 3000);
  };

  return { toasts, addToast };
};
