<template>
  <footer class="footer">
    <slot />
  </footer>
</template>

<style>
.footer {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-16);
}
</style>
