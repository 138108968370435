<script setup lang="ts">
import { IconName, IconSize, ButtonElement, ButtonVariant } from "@/types";
import Icon from "@/components/generic/Icon/Icon.component.vue";
import PfrButton from "@/components/generic/PfrButton/PfrButton.component.vue";
import { ref } from "vue";

const dialog = ref<HTMLDialogElement | null>(null);

defineProps({
  acceptLabel: {
    type: String,
    required: true,
  },
  cancelLabel: {
    type: String,
    default: undefined,
  },
  open: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(["accept", "close"]);

const openDialog = () => {
  dialog.value?.showModal();
};

const closeDialog = () => {
  dialog.value?.close();
  emits("close");
};

defineExpose({ openDialog });
</script>

<template>
  <Teleport to="body">
    <dialog ref="dialog" class="dialog" role="alertdialog" :open="open">
      <div class="dialog--content">
        <button
          class="dialog--close-button"
          type="button"
          aria-label="close"
          @click="closeDialog()"
        >
          <Icon :icon="IconName.Times" :size="IconSize.Small" :is-bold="true" />
        </button>
        <slot />

        <PfrButton
          :is="ButtonElement.Button"
          :full-width="true"
          @click="$emit('accept')"
        >
          {{ acceptLabel }}
        </PfrButton>

        <PfrButton
          :is="ButtonElement.Button"
          v-if="cancelLabel"
          :full-width="true"
          :variant="ButtonVariant.Secondary"
          @click="closeDialog()"
        >
          {{ cancelLabel }}
        </PfrButton>
      </div>
    </dialog>
  </Teleport>
</template>

<style>
.dialog {
  inline-size: fit-content;
  block-size: fit-content;
  border: none;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgb(32 34 40 / 0.2);
  background-color: var(--color-background-light);
}

.dialog--content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-16);
  position: relative;
  padding: var(--spacing-16);
}

.dialog::backdrop {
  background-color: var(--color-background-transparent);
}

.dialog--close-button {
  position: absolute;
  inset-block-start: var(--spacing-4);
  inset-inline-end: var(--spacing-4);
  color: var(--color-text-dark);

  &:focus,
  &:hover,
  &:active {
    color: var(--color-text-near-light);
  }
}
</style>
