import type { App } from "vue";
import * as Sentry from "@sentry/vue";

export const setupSentry = (app: App<Element>) => {
  if (!import.meta.env.VITE_SENTRY_DSN) return;

  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_SENTRY_ENV,
    integrations: [Sentry.browserTracingIntegration()],
    tracePropagationTargets: [import.meta.env.VITE_HOST_URL, /^\//],
    tracesSampleRate: 1.0,
  });
};
