<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path
      d="
        M19 3a10 10 0 0 0-7.8 16.3l-8 8 1.5 1.4 8-8A10 10 0 1 0 19 3Zm0 2a8 8 0
        1 1 0 16 8 8 0 1 1 0-16Z
      "
    />
  </svg>
</template>
