import { sendRequest, Methods } from "@/services/request";

interface ConfirmationPhotoData {
  confirmationPhoto: File;
  description: string;
  dateTime: Date;
}

const buildCreateConfirmationPhotoBody = (data: ConfirmationPhotoData) => {
  // ConfirmationPhoto is named photo inside the webportal
  const form = new FormData();
  form.append("photo[record_date_time]", data.dateTime.toISOString());
  form.append("photo[description]", data.description);
  form.append("photo[image]", data.confirmationPhoto, "confirmationPhoto.png");

  return form;
};

export const createConfirmationPhoto = async (
  weighingId: string,
  data: ConfirmationPhotoData,
) => {
  const path = `de/weighings/${weighingId}/photos`;
  const method = Methods.POST;

  try {
    const response = await sendRequest({
      path,
      method,
      body: buildCreateConfirmationPhotoBody(data),
    });

    return response.status === 201;
  } catch {
    return false;
  }
};
