import { defineStore } from "pinia";
import { buildBlanketOrder, isDefinedAndNotNull } from "@/helpers";
import { useGetBlanketOrdersQuery } from "@/types/graphql";
import type { BlanketOrder } from "@/types";

interface BlanketOrdersStore {
  blanketOrders: Array<BlanketOrder>;
  fetching: boolean;
}

export const useBlanketOrdersStore = defineStore("blanketOrders", {
  state: (): BlanketOrdersStore => ({
    blanketOrders: [],
    fetching: false,
  }),
  actions: {
    async fetchBlanketOrders() {
      this.fetching = true;
      const { data } = await useGetBlanketOrdersQuery({ variables: {} });
      this.fetching = false;

      if (data.value?.blanketOrders?.nodes) {
        this.blanketOrders = data.value.blanketOrders.nodes
          .map((blanketOrder) => buildBlanketOrder(blanketOrder))
          .filter(isDefinedAndNotNull);
      }
    },
  },
});
