<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import type { FormattedOrderItem } from "@/types";
import InfoList from "@/components/generic/InfoList/InfoList.component.vue";

interface Props {
  orderItem: FormattedOrderItem;
}

const props = defineProps<Props>();

const { t } = useI18n();

const entries = computed(() => {
  const entryList = [];

  if (
    props.orderItem.materialInfos &&
    props.orderItem.materialInfos.length > 0
  ) {
    entryList.push({
      key: t("order.card.details.material"),
      value: props.orderItem.materialInfos,
    });
  }

  if (props.orderItem.customer) {
    entryList.push({
      key: t("order.card.details.customer"),
      value: props.orderItem.customer,
    });
  }

  return entryList;
});
</script>

<template>
  <InfoList :entries="entries" />
</template>
