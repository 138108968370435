<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path
      d="
        M16 4a12 12 0 1 0 0 24 12 12 0 0 0 0-24Zm0 2a10 10 0 1 1 0 20 10 10
        0 0 1 0-20Zm-1 2v9h7v-2h-5V8Z
      "
    />
  </svg>
</template>
