export default {
  cancelOrder: {
    confirm: "Auftrag abbrechen",
    title: "Auftrag {orderNumber} abbrechen?",
    hint: "Der Auftrag ist unter @:views.openOrders.heading zu finden.",
    success: "Der Auftrag {orderNumber} wurde abgebrochen.",
  },
  imageUpload: {
    takePhoto: "Foto aufnehmen",
    photoPreview: "Vorschaufoto",
    deletePreviewPhoto: "Foto löschen",
  },
  languageSwitcher: {
    label: "Sprache",
    languages: {
      de: "Deutsch",
      en: "Englisch",
    },
  },
  loadingStateDisplay: {
    error: "Es ist leider etwas schief gelaufen :(",
    noInternetConnection: "Beladung ohne Internet",
    noInternetInfo:
      "Sie können wie gewohnt beladen. Sobald die Internetverbindung wieder besteht, werden die Wiegedaten automatisch übermittelt und Sie erhalten Zugriff auf den Lieferschein.",
    reload: "Neu laden",
  },
  search: {
    label: "Suche",
    cancel: "Abbrechen",
    placeholder: "Auftragsnummer / Kunde",
  },
  signature: {
    open: "Unterschriftfeld öffnen",
    delete: "Unterschrift löschen",
  },
};
