import components from "./en/components";
import deliveryNote from "./en/delivery-note";
import forms from "./en/forms";
import login from "./en/login";
import navigation from "./en/navigation";
import order from "./en/order";
import request from "./en/request";
import scale from "./en/scale";
import sidebar from "./en/sidebar";
import units from "./en/units";
import views from "./en/views";

export const en = {
  deliveryNote,
  forms,
  login,
  navigation,
  order,
  request,
  scale,
  sidebar,
  units,
  views,
  components,
};
