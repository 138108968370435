import { sendRequest, Methods } from "@/services/request";
import { LocalStorageItems } from "@/types";

export const requestCSRFToken = async () => {
  const path = "de/users/sign_in";
  const method = Methods.GET;

  return sendRequest({
    path,
    method,
  });
};

export const signIn = async (email: string, password: string) => {
  await requestCSRFToken();

  const mixpanelConsent = localStorage.getItem(
    LocalStorageItems.mixpanelConsent,
  );

  const form = new FormData();
  form.append("user[email]", email);
  form.append("user[password]", password);

  // If the user consented before logging in, the web portal will yet know about his or her decision.
  // We therefore add the mixpanel consent here when perfoming the login request.
  if (mixpanelConsent) form.append("mixpanel_consent", mixpanelConsent);

  const path = "de/users/sign_in";
  const method = Methods.POST;

  const response = await sendRequest({
    path,
    method,
    body: form,
  });

  return response;
};

export const signOut = async () => {
  const path = "de/users/sign_out";
  const method = Methods.DELETE;

  const response = await sendRequest({
    path,
    method,
  });

  return response.status === 204;
};
