<script setup lang="ts">
import { computed, type PropType } from "vue";
import Icon from "@/components/generic/Icon/Icon.component.vue";
import { type IconName, type BadgeStatus, IconSize } from "@/types";

const props = defineProps({
  status: {
    type: String as PropType<BadgeStatus>,
    required: true,
  },
  icon: {
    type: String as PropType<IconName>,
    required: true,
  },
  size: {
    type: Number as PropType<number>,
    default: IconSize.Medium,
  },
});

const modifier = computed(() => `is-${props.status}`);
</script>

<template>
  <div class="icon-badge" data-testid="icon-badge" :class="modifier">
    <div class="icon-badge--icon-background">
      <Icon
        class="icon-badge--icon"
        data-testid="icon-badge--icon"
        :icon="icon"
        :size="size"
      />
    </div>
  </div>
</template>

<style>
.icon-badge {
  inline-size: fit-content;
  border-radius: 50%;
  padding: var(--spacing-16);
  box-shadow: var(--elevation-13);
  background-color: var(--color-background-light);
}

.icon-badge--icon-background {
  border-radius: 50%;
  padding: var(--spacing-8);

  .icon-badge.is-success & {
    background-color: var(--color-success);
  }

  .icon-badge.is-warning & {
    background-color: var(--color-warning);
  }

  .icon-badge.is-error & {
    background-color: var(--color-error);
  }

  .icon-badge.is-info & {
    background-color: var(--color-info);
  }

  .icon-badge.is-neutral & {
    background-color: var(--color-neutral);
  }
}

.icon-badge--icon {
  fill: var(--color-icon-badge);
}
</style>
