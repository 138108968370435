<script setup lang="ts">
import { computed, type PropType } from "vue";
import { useI18n } from "vue-i18n";
import IconList from "@/components/generic/IconList/IconList.component.vue";
import {
  type Address,
  type ExtendedOrderItem,
  type ExtendedWeighing,
  IconName,
} from "@/types";

const props = defineProps({
  orderItem: {
    type: Object as PropType<ExtendedOrderItem | ExtendedWeighing>,
    required: true,
  },
});

const { t } = useI18n();

const buildAddress = (address: Address) => {
  const addressEntries = [];
  const street = `${address.street} ${address.houseNumber || ""}`.trim();
  const city = `${address.zipCode} ${address.city}`.trim();

  if (street) addressEntries.push(street);
  if (city) addressEntries.push(city);
  if (addressEntries.length === 0) return undefined;

  return addressEntries;
};

const deliveryDetailEntries = computed(() => [
  {
    icon: IconName.MapMarker,
    value: props.orderItem.address
      ? buildAddress(props.orderItem.address)
      : undefined,
    label: t("order.card.delivery.address"),
  },
]);
</script>

<template>
  <IconList :entries="deliveryDetailEntries" />
</template>
