<script setup lang="ts">
import { useToasts } from "@/composables";

const { toasts } = useToasts();
</script>

<template>
  <div class="pfreundt-stack">
    <template v-for="(toast, index) in toasts" :key="index">
      <Transition>
        <pfreundt-toast v-if="toast.visible" :status="toast.status">{{
          toast.message
        }}</pfreundt-toast>
      </Transition>
    </template>
  </div>
</template>

<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
