import type { RouteParams } from "vue-router";

export const extractParams = (
  routeParams: RouteParams,
  params: Array<string>,
): Record<string, string> =>
  params.reduce((obj, param) => {
    const currentParam = routeParams[param];

    if (!currentParam) return obj;

    return {
      ...obj,
      [param]: Array.isArray(currentParam) ? currentParam[0] : currentParam,
    };
  }, {});

export const extractCredentials = (queryString: string): Array<string> => {
  const urlParams = new URLSearchParams(queryString);
  const email = urlParams.get("email") || "";
  const password = urlParams.get("password") || "";
  return [email, password];
};
