import { useUserStore } from "@/stores/user.store";
import type { Mixpanel } from "mixpanel-browser";
import { ref } from "vue";

const isLoggedIn = ref(false);
const LOGOUT_EVENT_NAME = "Load&Go Sign Out";

export function useMixpanel(mixpanel?: Mixpanel) {
  const userStore = useUserStore();

  const identify = (id: string) => {
    if (!mixpanel || !userStore.mixpanelConsent) return;

    mixpanel.identify(id);
    isLoggedIn.value = true;
  };

  const reset = () => {
    if (!mixpanel || !userStore.mixpanelConsent) return;

    mixpanel.track(LOGOUT_EVENT_NAME);
    mixpanel.reset();
    isLoggedIn.value = false;
  };

  const trackPageView = (eventName: string) => {
    const { user } = userStore;
    if (!user || !mixpanel || !userStore.mixpanelConsent) return;

    if (!isLoggedIn.value) identify(user.id);
    mixpanel.track_pageview({}, { event_name: eventName });
  };

  return { trackPageView, reset, identify };
}
