<script setup lang="ts">
import { computed, type PropType } from "vue";
import { useI18n } from "vue-i18n";
import type { ExtendedOrderItem, ExtendedWeighing } from "@/types";
import InfoList from "@/components/generic/InfoList/InfoList.component.vue";
import { buildMaterial, buildWeight } from "@/helpers";

const props = defineProps({
  entity: {
    type: Object as PropType<ExtendedOrderItem | ExtendedWeighing>,
    required: true,
  },
  hasWeightRow: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
});

const { t } = useI18n();

const entries = computed(() => {
  const defaultEntries = [
    {
      key: t("order.card.details.customer"),
      value: props.entity.customer ? props.entity.customer.name : undefined,
    },
    {
      key: t("order.card.details.material"),
      value: props.entity.material
        ? buildMaterial(props.entity, !props.hasWeightRow)
        : undefined,
    },
  ];

  if (!props.hasWeightRow) return defaultEntries;

  const weightEntry = {
    key: t("order.card.details.total"),
    value: props.entity.material ? buildWeight(props.entity) : undefined,
  };
  defaultEntries.unshift(weightEntry);

  return defaultEntries;
});
</script>

<template>
  <InfoList :entries="entries" />
</template>
