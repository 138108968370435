<script setup lang="ts">
import { computed, type PropType } from "vue";
import { useI18n } from "vue-i18n";
import { format } from "date-fns";
import { type ExtendedWeighing } from "@/types";
import { buildMaterial, isAnySignatureMissing } from "@/helpers";
import InfoList from "@/components/generic/InfoList/InfoList.component.vue";

const props = defineProps({
  weighing: {
    type: Object as PropType<ExtendedWeighing>,
    required: true,
  },
});

const { t } = useI18n();

const deliveryNoteDetailEntries = computed(() => [
  {
    key: t("order.card.details.material"),
    value: props.weighing.material
      ? buildMaterial(props.weighing, true)
      : undefined,
  },
  {
    key: t("order.card.delivery.date"),
    value: props.weighing.date
      ? format(props.weighing.date, "dd.MM.yyyy, HH:mm")
      : undefined,
  },
  { key: t("order.card.delivery.driver"), value: props.weighing.driver?.name },
]);
</script>

<template>
  <div class="pfreundt-stack">
    <InfoList :entries="deliveryNoteDetailEntries" />

    <div v-if="!isAnySignatureMissing(props.weighing)">
      <pfreundt-badge
        status="success"
        icon="check_circle"
        icon-variant="outlined"
        :text="t('order.card.details.signed')"
      />
    </div>
  </div>
</template>
