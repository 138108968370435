<script setup lang="ts">
import { provideClient } from "@urql/vue";
import { RouterView, useRouter } from "vue-router";
import { watch, onMounted } from "vue";
import { client, buildUrqlClient } from "@/setup/graphql";
import { useAuthStore, useUserStore } from "@/stores";
import { setupUserCentricsBanner } from "@/helpers";

const router = useRouter();
const authStore = useAuthStore();
watch(
  () => authStore.loggedIn,
  async (newState, oldState) => {
    if (!newState && oldState) {
      client.value = buildUrqlClient();
      router.push("/login");
    }
    if (newState && !oldState && !authStore.legalAcceptNeeded) {
      const userStore = useUserStore();
      if (!userStore.user) {
        await userStore.fetchUser();
      }
    }
  },
);

provideClient(client);

const userStore = useUserStore();

onMounted(() => {
  setupUserCentricsBanner(userStore.setMixpanelConsent);
});
</script>

<template>
  <RouterView />
</template>
