<script setup lang="ts">
import { computed, type PropType } from "vue";
import { useI18n } from "vue-i18n";
import type { ExtendedOrderItem, BlanketOrder } from "@/types";
import OrderCard from "@/components/generic/OrderCard/OrderCard.component.vue";

const props = defineProps({
  combinedOrders: {
    type: Array as PropType<Array<ExtendedOrderItem | BlanketOrder>>,
    required: true,
  },
});

const { t } = useI18n();

const orderCount = computed(() =>
  t("order.hint", { count: props.combinedOrders.length }),
);
</script>

<template>
  <div class="order-index-main--hint">
    {{ orderCount }}
  </div>
  <div class="order-index-main--orders">
    <template
      v-for="combinedOrder in combinedOrders"
      :key="`${combinedOrder.type}-${combinedOrder.id}`"
    >
      <OrderCard :entry="combinedOrder" />
    </template>
  </div>
</template>

<style>
.order-index-main--hint {
  margin-block-end: var(--spacing-24);
  color: var(--color-text-darker);
}

.order-index-main--orders {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-16);
}
</style>
