export enum ToastStatus {
  Success = "success",
  Error = "error",
  Warning = "warning",
  Info = "info",
}

export interface Toast {
  id: number;
  message: string;
  status: ToastStatus;
  visible: boolean;
}
