import type { Component } from "vue";
import {
  AngleLeftIcon,
  AngleRightIcon,
  BarsIcon,
  CheckCircleIcon,
  CheckIcon,
  ClockIcon,
  CogIcon,
  DirectionIcon,
  EnvelopeIcon,
  ExclamationCircleIcon,
  ExclamationIcon,
  ExclamationTriangleIcon,
  FileIcon,
  HistoryIcon,
  InfoCircleIcon,
  LanguageIcon,
  LocationIcon,
  MapMarkerIcon,
  PaperPlaneIcon,
  PauseIcon,
  PenIcon,
  PlayCircleIcon,
  PlayIcon,
  PlusCircleIcon,
  QuestionCircleIcon,
  RedoIcon,
  SearchIcon,
  SignOutIcon,
  TimesIcon,
  TrashIcon,
  TruckIcon,
  WifiOffIcon,
} from "../components/generic/Icon/icons";

export enum IconName {
  AngleLeft = "angle-left",
  AngleRight = "angle-right",
  Bars = "bars",
  CheckCircle = "check-circle",
  Check = "check",
  Clock = "clock",
  Cog = "cog",
  Direction = "direction",
  Envelope = "envelope",
  ExclamationCircle = "exclamation-circle",
  Exclamation = "exclamation",
  ExclamationTriangle = "exclamation-triangle",
  File = "file",
  History = "history",
  InfoCircle = "info-circle",
  Language = "language",
  Location = "location",
  MapMarker = "map-marker",
  PaperPlane = "paper-plane",
  Pause = "pause",
  Pen = "pen",
  PlayCircle = "play-circle",
  Play = "play",
  PlusCircle = "plus-circle",
  QuestionCircle = "question-circle",
  Redo = "redo",
  Search = "search",
  SignOut = "sign-out",
  Times = "times",
  Trash = "trash",
  Truck = "truck",
  WifiOff = "wifi-off",
}

export enum IconSize {
  Tiny = 18,
  Small = 20,
  Medium = 24,
  Large = 30,
}

type IconMap = {
  [key in IconName]: Component;
};

export const icons: IconMap = {
  [IconName.AngleLeft]: AngleLeftIcon,
  [IconName.AngleRight]: AngleRightIcon,
  [IconName.Check]: CheckIcon,
  [IconName.Bars]: BarsIcon,
  [IconName.CheckCircle]: CheckCircleIcon,
  [IconName.Clock]: ClockIcon,
  [IconName.Cog]: CogIcon,
  [IconName.Direction]: DirectionIcon,
  [IconName.Envelope]: EnvelopeIcon,
  [IconName.ExclamationCircle]: ExclamationCircleIcon,
  [IconName.Exclamation]: ExclamationIcon,
  [IconName.ExclamationTriangle]: ExclamationTriangleIcon,
  [IconName.File]: FileIcon,
  [IconName.History]: HistoryIcon,
  [IconName.InfoCircle]: InfoCircleIcon,
  [IconName.Language]: LanguageIcon,
  [IconName.Location]: LocationIcon,
  [IconName.MapMarker]: MapMarkerIcon,
  [IconName.PaperPlane]: PaperPlaneIcon,
  [IconName.Pause]: PauseIcon,
  [IconName.Pen]: PenIcon,
  [IconName.PlayCircle]: PlayCircleIcon,
  [IconName.Play]: PlayIcon,
  [IconName.PlusCircle]: PlusCircleIcon,
  [IconName.QuestionCircle]: QuestionCircleIcon,
  [IconName.Redo]: RedoIcon,
  [IconName.Search]: SearchIcon,
  [IconName.SignOut]: SignOutIcon,
  [IconName.Times]: TimesIcon,
  [IconName.Trash]: TrashIcon,
  [IconName.Truck]: TruckIcon,
  [IconName.WifiOff]: WifiOffIcon,
};
