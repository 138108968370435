<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { IconName } from "@/types";
import { onMounted, ref, nextTick } from "vue";
import FieldInput from "../FieldInput/FieldInput.component.vue";
import Icon from "../Icon/Icon.component.vue";

const model = defineModel({ type: String });
const fieldInput = ref<InstanceType<typeof FieldInput> | null>(null);

const { t } = useI18n();

onMounted(() => {
  nextTick(() => {
    if (fieldInput.value && fieldInput.value.input)
      fieldInput.value.input.focus();
  });
});
</script>

<template>
  <div class="search">
    <FieldInput
      id="search"
      ref="fieldInput"
      v-model="model"
      :is-required="true"
      type="search"
      field-name="search"
      :hidden-label="true"
      :sync-v-model="true"
      :label="t('components.search.label')"
      :placeholder="t('components.search.placeholder')"
    >
      <template #iconBefore>
        <Icon :icon="IconName.Search" />
      </template>
    </FieldInput>

    <slot />
  </div>
</template>

<style>
.search {
  --size-search-max-inline-size: 480px;

  display: flex;
  align-items: center;
  gap: var(--spacing-8);
  inline-size: 100%;
  max-inline-size: var(--size-search-max-inline-size);
  block-size: 100%;
  margin-inline: auto;

  /* Ensure similar heights for title headers and search bars */
  padding-block: 1px;
}

.form--group {
  .search &:first-child {
    flex: 1;
    margin-block-end: 0;
  }
}
</style>
