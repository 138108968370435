<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path
      d="
        m13.2 3-.2.8-.6 3c-1 .4-1.8.9-2.6 1.5L7 7.3l-.7-.2-.5.7-2 3.4-.4.7.6.6
        2.3 2L6 16l.2 1.6-2.3 2-.6.5.5.7 2 3.4.4.7.7-.2 3-1a10 10 0 0 0 2.5
        1.5l.6 3 .2.8h5.6l.2-.8.6-3c1-.4 1.8-.9 2.6-1.5l2.9 1 .7.2.4-.7
        2-3.4.5-.7-.6-.6-2.3-2L26 16l-.2-1.6 2.3-2 .6-.5-.4-.7-2-3.4-.5-.7-.7.2-3
        1a10 10 0 0 0-2.5-1.5l-.6-3-.2-.8Zm1.6 2h2.4l.5 2.6.1.6.6.2a8 8 0 0 1 3
        1.7l.4.4.6-.2 2.5-.8 1.2 2-2 1.8-.4.4.1.6a7.9 7.9 0 0 1 0 3.4l-.1.6.4.4 2
        1.8-1.2 2-2.5-.8-.6-.2-.4.4a8 8 0 0 1-3 1.7l-.6.2-.1.6-.5
        2.6h-2.4l-.5-2.6-.1-.6-.6-.2a8 8 0 0 1-3-1.7l-.4-.4-.6.2-2.5.8-1.2-2
        2-1.8.4-.4-.1-.6a7.9 7.9 0 0 1 0-3.4l.1-.6-.4-.4-2-1.8 1.2-2
        2.5.8.6.2.4-.4a8 8 0 0 1 3-1.7l.6-.2.1-.6Zm1.2 6a5 5 0 0 0-5 5 5 5 0 0 0
        5 5 5 5 0 0 0 5-5 5 5 0 0 0-5-5Zm0 2a3 3 0 0 1 3 3 3 3 0 0 1-3 3 3 3 0 0
        1-3-3 3 3 0 0 1 3-3Z
      "
    />
  </svg>
</template>
