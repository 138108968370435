import { defineStore } from "pinia";
import {
  GetUserDocument,
  type Driver,
  type User,
  type Vehicle,
} from "@/types/graphql";
import { client } from "@/setup/graphql";
import { useLocalStorage } from "@vueuse/core";
import { type Ref } from "vue";
import { LocalStorageItems, type UserCentricsCustomEvent } from "@/types";
import { updateConsent } from "@/services/userConsent";

interface UserStore {
  user: User | null;
  fetching: boolean;
  error: boolean;
  driverId: Ref<string | null>;
  vehicleId: Ref<string | null>;
  mixpanelConsent: Ref<boolean>;
}

export const useUserStore = defineStore("user", {
  state: (): UserStore => ({
    user: null,
    fetching: false,
    error: false,
    driverId: useLocalStorage(LocalStorageItems.driverId, null),
    vehicleId: useLocalStorage(LocalStorageItems.vehicleId, null),
    mixpanelConsent: useLocalStorage(LocalStorageItems.mixpanelConsent, false),
  }),
  actions: {
    async fetchUser() {
      this.user = null;
      // Here it is not possible to use the useQuery function as usual.
      // Since this query is used to find out the login status(see setup/router/index.ts),
      // it is used outside the setup.
      // Outside the setup the useQuery method is not allowed.
      const result = await client.value.query(GetUserDocument, {}).toPromise();
      if (result.data?.user) {
        this.user = result.data.user;
      }
    },
    setDriverId(id: string | null) {
      this.driverId = id;
    },
    setVehicleId(id: string | null) {
      this.vehicleId = id;
    },
    setMixpanelConsent(event: UserCentricsCustomEvent) {
      if (this.mixpanelConsent === event.detail.Mixpanel) return;

      this.mixpanelConsent = event.detail.Mixpanel;
      updateConsent(event.detail.Mixpanel);
    },
    checkDriverId(drivers: Array<Driver>) {
      if (!this.driverId) return;
      if (drivers.some((driver) => driver.id === this.driverId)) return;

      this.driverId = null;
    },
    checkVehicleId(vehicles: Array<Vehicle>) {
      if (!this.vehicleId) return;
      if (vehicles.some((vehicle) => vehicle.id === this.vehicleId)) return;

      this.vehicleId = null;
    },
  },
});
