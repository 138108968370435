import {
  OrderState,
  type Address,
  type ExtendedOrderItem,
  type ExtendedWeighing,
} from "@/types";
import { useFormatUnit } from "./units/unit.helper";

const getOrderItemWeightAccessor = (entity: ExtendedOrderItem) =>
  entity.state === OrderState.Closed ? "weightReached" : "targetWeight";

export const buildAddress = (address: Address) => [
  `${address.street} ${address.houseNumber || ""}`.trim(),
  `${address.zipCode} ${address.city}`,
];

export const buildWeight = (entity: ExtendedOrderItem | ExtendedWeighing) => {
  const { formatUnit } = useFormatUnit();

  if (entity.type === "order") {
    const weightAccessor = getOrderItemWeightAccessor(entity);
    return `${formatUnit(entity[weightAccessor], entity.unit)}`;
  }

  return `${formatUnit(entity.weight, entity.unit)}`;
};

export const buildMaterial = (
  entity: ExtendedOrderItem | ExtendedWeighing,
  showWeight: boolean,
) => {
  const { formatUnit } = useFormatUnit();

  if (entity.type === "order") {
    const weightAccessor = getOrderItemWeightAccessor(entity);
    return `${
      showWeight ? formatUnit(entity[weightAccessor], entity.unit) : ""
    } ${entity.material?.name}`.trim();
  }

  return `${showWeight ? formatUnit(entity.weight, entity.unit) : ""} ${
    entity.material?.name
  }`.trim();
};
