import {
  createClient,
  cacheExchange,
  fetchExchange,
  Client,
  subscriptionExchange,
  mapExchange,
  type Operation,
  CombinedError,
  type OperationResult,
  type AnyVariables,
} from "urql";

import { authExchange, type AuthUtilities as Utils } from "@urql/exchange-auth";
import { ref, type Ref } from "vue";
import { useAuthStore } from "@/stores/auth";
import { getCSRFToken } from "@/helpers/authHelper";
import { useToasts } from "@/composables/toasts";
import { ToastStatus } from "@/types";
import { extractPayloadErrors } from "@/helpers/error/error.helper";
import { actionCableClient } from "./ActionCable";

const logout = () => {
  const authStore = useAuthStore();
  authStore.forcedLogout = true;
  // setting the logout state
  authStore.logout();
};

const setErrorToasts = (result: OperationResult<any, AnyVariables>) => {
  const errors = extractPayloadErrors(result);
  if (errors.length === 0) return;

  const { addToast } = useToasts();
  errors.forEach((error) => {
    addToast(error, ToastStatus.Error);
  });
};

const addAuthToOperation = (utils: Utils, operation: Operation) => {
  const csrfToken = getCSRFToken();

  if (!csrfToken) {
    return operation;
  }

  return utils.appendHeaders(operation, {
    "X-CSRF-TOKEN": csrfToken,
  });
};

const didAuthError = (error: CombinedError) => error?.response?.status === 401;
const isInternalServerError = (error: CombinedError) =>
  error?.response?.status === 500;

// Each time data is requested, the user is authenticated.
// In the router authStore.loggedIn is used to see the current login status.
// Since there is the information about it here, the login status is also set here centrally.
export const buildUrqlClient = (): Client =>
  createClient({
    url: `${import.meta.env.VITE_WEBPORTAL_URL}/graphql`,
    exchanges: [
      cacheExchange,
      mapExchange({
        onError(error) {
          if (didAuthError(error)) {
            logout();
          } else if (isInternalServerError(error)) {
            const { addToast } = useToasts();
            addToast("Something went wrong", ToastStatus.Error);
          }
        },
        onResult(result) {
          if (
            result.data !== undefined &&
            result.error?.response?.status !== 401
          ) {
            const authStore = useAuthStore();
            // setting the logged in state
            authStore.loggedIn = true;
          }
          setErrorToasts(result);

          return result;
        },
      }),
      authExchange(async (utils) => ({
        addAuthToOperation: (operation) => addAuthToOperation(utils, operation),
        didAuthError,
        refreshAuth: async () => {},
      })),
      fetchExchange,
      subscriptionExchange({
        forwardSubscription: (operation) =>
          actionCableClient.request(operation),
      }),
    ],
    fetchOptions: {
      headers: {
        Accept: "application/json",
      },
      credentials: "include",
    },
    requestPolicy: "network-only",
  });

export const client: Ref<Client> = ref(buildUrqlClient());
