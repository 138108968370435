import { CombinedError, type AnyVariables, type OperationResult } from "urql";

export const buildError = (
  graphQLErrors: string[] | undefined,
  responseErrors: CombinedError | undefined,
): CombinedError | undefined => {
  if (Array.isArray(graphQLErrors) && graphQLErrors.length) {
    return new CombinedError({
      graphQLErrors,
    });
  }

  return responseErrors;
};

// The errors are nested beneath the name of the operation.
// To reuse this method for different operations, the operation name is not used and the first element is taken instead.
// This should always be the operation name.
export const extractPayloadErrors = (
  result: OperationResult<any, AnyVariables>,
): Array<string> => {
  if (result?.data === undefined) return [];
  const key = Object.keys(result.data)[0];

  return result.data[key].errors ?? [];
};
