import { defineRule, configure } from "vee-validate";
import { required } from "@vee-validate/rules";
import type { buildI18n } from "@/setup/i18n";

export const setupValidation = (i18n: ReturnType<typeof buildI18n>) => {
  const {
    global: { t },
  }: any = i18n;

  defineRule("required", required);

  defineRule("maxFiles", (value: FileList, [max]: [max: number]) => {
    if (!value) return true;

    if (value.length <= max) {
      return true;
    }

    return t(`forms.validations.maxFiles`, { limit: max });
  });

  configure({
    generateMessage: ({ rule }) => {
      if (!rule) return "";

      return t(`forms.validations.${rule.name}`);
    },
  });
};
