<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path
      d="
        M16 3a13 13 0 1 0 0 26 13 13 0 0 0 0-26Zm0 2a11 11 0 1 1 0 22
        11 11 0 0 1 0-22Zm-1 5v5h-5v2h5v5h2v-5h5v-2h-5v-5Z
      "
    />
  </svg>
</template>
