<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path
      d="
        M24 4c-1.1 0-2.2.4-3 1.2L5.2 21v.3L4 26.8l-.3 1.5 1.5-.3
        5.5-1.1h.3L26.8 11a4 4 0 0 0 0-5.8A4.1 4.1 0 0 0 24 4Zm0 1.9c.4 0 1 .2
        1.4.7 1 1 1 2 0 3l-.7.7-3-3 .7-.7a2 2 0 0 1 1.5-.7Zm-3.7 2.8 3 3-12.1
        12a6.8 6.8 0 0 0-3-2.9ZM7 22.4a5 5 0 0 1 2.7 2.7l-3.3.6Z
      "
    />
  </svg>
</template>
