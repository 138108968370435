<script lang="ts" setup>
import { watch, ref, type PropType, onMounted } from "vue";
import LoadingSpinner from "@/components/generic/LoadingSpinner/LoadingSpinner.component.vue";
import PfrButton from "@/components/generic/PfrButton/PfrButton.component.vue";
import ErrorMessage from "@/components/generic/ErrorMessage/ErrorMessage.component.vue";
import { IconName, LoadingState, ButtonElement, ButtonSize } from "@/types";

const MIN_LOADING_TIME = 350;

const props = defineProps({
  loadingState: {
    type: String as PropType<LoadingState>,
    required: true,
  },
});

let mountTime: number;
onMounted(() => {
  mountTime = Date.now();
});

// Wait for at least MIN_LOADING_TIME to avoid UI flickering if success state occurs very fast
const debouncedLoadingState = ref(LoadingState.Loading);
watch(
  () => props.loadingState,
  (newLoadingState) => {
    const timeElapsed = Date.now() - mountTime;
    const successOccuredEarly =
      newLoadingState === LoadingState.Success &&
      timeElapsed < MIN_LOADING_TIME;

    const timeToWait = successOccuredEarly ? MIN_LOADING_TIME - timeElapsed : 0;

    setTimeout(() => {
      debouncedLoadingState.value = newLoadingState;
    }, timeToWait);
  },
  { immediate: true },
);

const reloadPage = () => {
  window.location.reload();
};
</script>

<template>
  <slot v-if="debouncedLoadingState === LoadingState.Loading" name="loading">
    <div class="loading-state-display--spinner">
      <LoadingSpinner />
    </div>
  </slot>
  <slot
    v-else-if="debouncedLoadingState === LoadingState.GraphqlError"
    name="error"
  >
    {{ $t("components.loadingStateDisplay.error") }}
  </slot>
  <div v-else>
    <ErrorMessage
      v-if="debouncedLoadingState === LoadingState.NetworkError"
      :icon-name="IconName.WifiOff"
      :title="$t('components.loadingStateDisplay.noInternetConnection')"
      :description="$t('components.loadingStateDisplay.noInternetInfo')"
    >
      <template #action>
        <PfrButton
          :is="ButtonElement.Button"
          :icon="IconName.Redo"
          :size="ButtonSize.Small"
          :full-width="true"
          @click="reloadPage()"
        >
          {{ $t("components.loadingStateDisplay.reload") }}
        </PfrButton>
      </template>
    </ErrorMessage>
    <slot />
  </div>
</template>

<style>
.loading-state-display--spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  inline-size: 100%;
  block-size: 100%;
}
</style>
