import { defineStore } from "pinia";
import { CombinedError } from "urql";
import { ref, type Ref } from "vue";
import { buildExtendedWeighing, serializeRawOrder } from "@/helpers";
import {
  saveSignature as saveSignatureService,
  createConfirmationPhoto,
} from "@/services";
import type { ExtendedWeighing, ConfirmationPhoto } from "@/types";
import { useGetOrderQuery } from "@/types/graphql";

interface WeighingStore {
  weighing: ExtendedWeighing | null;
  fetching: boolean;
  error: Ref<CombinedError | undefined>;
}

const serializeConfirmationPhoto = (confirmationPhoto: ConfirmationPhoto) => ({
  confirmationPhoto: confirmationPhoto.file,
  description: "Confirmation photo from Load & Go",
  dateTime: new Date(),
});

const saveWeighingSignature = async (
  weighing: ExtendedWeighing | null,
  signature: Blob,
  description: string,
  role: "driver" | "customer",
) => {
  if (!weighing) return false;

  const saveSignatureData = {
    signature,
    description,
    dateTime: new Date(),
    role,
  };

  return saveSignatureService(weighing.id, saveSignatureData);
};

export const useWeighingStore = defineStore("weighing", {
  state: (): WeighingStore => ({
    weighing: null,
    fetching: false,
    error: ref(undefined),
  }),
  actions: {
    async fetchWeighing(
      orderId: string,
      orderItemId: string,
      weighingId: string,
    ) {
      this.fetching = true;
      const { data, error: originalError } = await useGetOrderQuery({
        variables: { id: orderId, withWeighings: true },
      });
      this.fetching = false;
      this.error = originalError.value;

      if (!data.value?.order) {
        if (!this.error) {
          this.error = new CombinedError({ graphQLErrors: ["No Order given"] });
        }
        return;
      }
      const serializedOrder = serializeRawOrder(data.value.order);
      const weighing = buildExtendedWeighing(
        serializedOrder,
        orderItemId,
        weighingId,
      );

      if (!weighing) {
        if (!this.error) {
          this.error = new CombinedError({
            graphQLErrors: ["No Weighing given"],
          });
        }
        return;
      }
      this.weighing = weighing;
    },
    async saveConfirmationPhotos(photos: ConfirmationPhoto[]) {
      const photoSaveResults = await Promise.all(
        photos.map((photo) => {
          if (!this.weighing) {
            return {
              photo,
              result: false,
            };
          }

          return createConfirmationPhoto(
            this.weighing.id,
            serializeConfirmationPhoto(photo),
          ).then((result) => ({
            photo,
            result,
          }));
        }),
      );

      return photoSaveResults
        .filter(({ result }) => !result)
        .map(({ photo }) => photo);
    },
    async saveDriversSignature(signature: Blob, userName: string) {
      return saveWeighingSignature(
        this.weighing,
        signature,
        userName,
        "driver",
      );
    },
    async saveCustomersSignature(signature: Blob, customersName: string) {
      return saveWeighingSignature(
        this.weighing,
        signature,
        customersName,
        "customer",
      );
    },
  },
});
