import Hotjar from "@hotjar/browser";

export const hotjar = {
  install() {
    const siteId = import.meta.env.VITE_HOTJAR_ID;
    const version = 6;

    Hotjar.init(siteId, version);
  },
};
