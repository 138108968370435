import SignaturePad, { type Options } from "signature_pad";
import { type Ref, watch, ref } from "vue";

const IMAGE_MIME_TYPE = "image/png";

const resizeCanvas = (canvasElement: HTMLCanvasElement) => {
  const canvas = canvasElement;
  const ratio = Math.max(window.devicePixelRatio || 1, 1);
  canvas.width = canvas.offsetWidth * ratio;
  canvas.height = canvas.offsetHeight * ratio;
  canvas.getContext("2d")?.scale(ratio, ratio);
};

const initializeResizeObserver = (
  canvas: HTMLCanvasElement,
  onResize: () => void,
) => {
  const resizeObserver = new ResizeObserver(() => onResize());
  resizeObserver.observe(canvas);

  return resizeObserver;
};

const initializeSignaturePad = (
  canvas: HTMLCanvasElement,
  options: Options,
  onBeginStroke: () => void,
  onEndStroke: (imageBlob: Blob | null) => void,
) => {
  const signaturePad = new SignaturePad(canvas, options);
  signaturePad.addEventListener("beginStroke", () => onBeginStroke());
  signaturePad.addEventListener("endStroke", () =>
    canvas.toBlob(onEndStroke, IMAGE_MIME_TYPE),
  );
  return signaturePad;
};

export const useSignatureField = (
  imageBlob: Ref<Blob>,
  onEndStroke: (imageBlob: Blob | null) => void,
  canvasElement: Ref<HTMLCanvasElement | undefined>,
  options: Options = {},
) => {
  let resizeObserver: ResizeObserver;
  let signaturePad: SignaturePad;
  const isClear = ref(true);

  watch(canvasElement, (canvas) => {
    if (!canvas) return;

    signaturePad = initializeSignaturePad(
      canvas,
      options,
      () => {
        isClear.value = false;
      },
      onEndStroke,
    );

    resizeObserver = initializeResizeObserver(canvas, () => {
      resizeCanvas(canvas);
      isClear.value = true;
      signaturePad.clear();
    });

    signaturePad.clear();
  });

  return {
    disconnect: () => resizeObserver.disconnect(),
    reset: () => {
      signaturePad.clear();
      isClear.value = true;
    },
    imageBlob,
    isClear,
  };
};
