import { defineStore } from "pinia";
import { signIn, signOut } from "@/services/auth";

export const useAuthStore = defineStore("authStore", {
  state: () => ({
    loggedIn: false,
    forcedLogout: false,
    legalAcceptNeeded: false,
  }),

  actions: {
    async login(email: string, password: string) {
      try {
        const response = await signIn(email, password);
        this.legalAcceptNeeded = response.status === 451;
        this.loggedIn = response.status === 201 || response.status === 451;
        const json = await response.json();
        const { message, legal_url: legalUrl } = json;
        this.forcedLogout = false;
        return { success: this.loggedIn, message, legalUrl };
      } catch (e) {
        return { success: false, message: null, legalUrl: null };
      }
    },
    async logout() {
      try {
        await signOut();
      } finally {
        this.loggedIn = false;
      }
    },
  },
});
