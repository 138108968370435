<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path
      d="
        M7.2 5.8 5.8 7.2l8.8 8.8-8.8 8.8 1.4 1.4 8.8-8.8 8.8 8.8
        1.4-1.4-8.8-8.8 8.8-8.8-1.4-1.4-8.8 8.8Z
      "
    />
  </svg>
</template>
