import { defineStore } from "pinia";
import { useGetVehiclesQuery } from "@/types/graphql";
import type { Vehicle } from "@/types";
import { useI18n } from "vue-i18n";

interface VehicleStore {
  vehicles: Array<Vehicle>;
}

export const useVehiclesStore = defineStore("vehicles", {
  state: (): VehicleStore => ({
    vehicles: [],
  }),
  actions: {
    async fetchVehicles() {
      const { data } = await useGetVehiclesQuery({ variables: {} });

      if (data.value?.vehicles?.nodes) {
        this.vehicles = data.value.vehicles.nodes;
      }
    },
  },
  getters: {
    vehicleOptions: (state) =>
      state.vehicles.map((vehicle) => ({
        label:
          vehicle.licensePlate ||
          useI18n().t("views.selectDriverInformation.noLicensePlate"),
        value: vehicle.id,
      })),
  },
});
