<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path
      d="
        m3.6 5.3.4 2L6 16l-2 8.8-.4 1.9 1.8-.8 22-9 2.3-.9-2.3-1-22-9Zm2.8
        3.4L21.9 15H7.8ZM7.8 17h14.1L6.4 23.3Z
      "
    />
  </svg>
</template>
