import { Methods, sendRequest } from "@/services/request";

export const updateConsent = async (mixpanelConsent: boolean) => {
  const path = "/de/users/manage_consent";
  const method = Methods.PATCH;

  await sendRequest({
    path,
    method,
    body: {
      mixpanel_consent: mixpanelConsent,
    },
  });
};
