<script setup lang="ts">
import { type PropType, computed, ref, onMounted, inject } from "vue";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import LanguageSwitcher from "@/components/generic/LanguageSwitcher/LanguageSwitcher.component.vue";
import PfreundtLogo from "@/components/generic/PfreundtLogo/PfreundtLogo.component.vue";
import SidebarNavigationLink from "@/components/generic/SidebarNavigation/Link/Link.component.vue";
import { IconName, RouteName } from "@/types";
import {
  useAuthStore,
  useUserStore,
  useVehiclesStore,
  useDriversStore,
} from "@/stores";
import { useMixpanel } from "@/composables";
import type { Mixpanel } from "mixpanel-browser";
import { useLink } from "vue-router";

defineProps({
  isOpen: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
});

defineEmits(["close"]);

const { t } = useI18n();

const authStore = useAuthStore();
const userStore = useUserStore();
const driversStore = useDriversStore();
const vehiclesStore = useVehiclesStore();
const { fetchDrivers } = driversStore;
const { fetchVehicles } = vehiclesStore;

const { drivers } = storeToRefs(driversStore);
const { vehicles } = storeToRefs(vehiclesStore);

const selectedDriverId = ref(userStore.driverId);
const selectedVehicleId = ref(userStore.vehicleId);
const { reset } = useMixpanel(inject<Mixpanel>("mixpanel"));

const signOut = () => {
  reset();
  authStore.logout();
};

const { user } = storeToRefs(userStore);

const userName = computed(() => {
  if (user.value == null) {
    return "";
  }
  if (user.value.firstname == null && user.value.lastname == null) {
    return t("sidebar.linkSharing");
  }
  return `${user.value.firstname} ${user.value.lastname}`.trim();
});

const vehicleLicensePlate = computed(
  () =>
    vehicles.value.find((vehicle) => vehicle.id === selectedVehicleId.value)
      ?.licensePlate ?? "-",
);
const driverName = computed(
  () =>
    drivers.value.find((driver) => driver.id === selectedDriverId.value)
      ?.name ?? "-",
);

onMounted(() => {
  fetchDrivers();
  fetchVehicles();
});

const { href } = useLink({
  to: { name: RouteName.SelectDriverInformation },
});
</script>

<template>
  <div :class="{ 'is-open': isOpen }" class="sidebar-navigation">
    <nav class="sidebar-navigation--nav" data-testid="navbar">
      <div class="sidebar-navigation--logo">
        <PfreundtLogo :is-inverted="true" :is-centered="false" />
      </div>

      <div class="sidebar-navigation--info">
        <div class="sidebar-navigation--info-start">
          <pfreundt-info-list>
            <dt>{{ t("views.selectDriverInformation.account") }}</dt>
            <dd>{{ userName }}</dd>
            <dt>{{ t("views.selectDriverInformation.drivername") }}</dt>
            <dd>{{ driverName }}</dd>
            <dt>{{ t("views.selectDriverInformation.vehicle") }}</dt>
            <dd>{{ vehicleLicensePlate }}</dd>
          </pfreundt-info-list>
        </div>

        <div class="sidebar-navigation--info-end">
          <pfreundt-button
            variant="text"
            color="monochrome"
            size="medium"
            icon-before="edit"
            icon-only
            :href="href"
            button-type="link"
            :aria-label="$t('sidebar.changeData')"
          >
          </pfreundt-button>
        </div>
      </div>

      <ul class="sidebar-navigation--nav-list">
        <SidebarNavigationLink
          :route-name="RouteName.MyOrders"
          :icon="IconName.Truck"
          :label="$t('sidebar.myOrders')"
        />

        <SidebarNavigationLink
          :route-name="RouteName.OpenOrders"
          :icon="IconName.Search"
          :label="$t('sidebar.openOrders')"
        />

        <SidebarNavigationLink
          :route-name="RouteName.ClosedOrderIndex"
          :icon="IconName.History"
          :label="$t('sidebar.closedOrders')"
        />

        <SidebarNavigationLink
          :icon="IconName.SignOut"
          :label="$t('sidebar.logout')"
          @click="signOut"
        />
      </ul>

      <footer class="sidebar-navigation--footer">
        <LanguageSwitcher />
        <a
          :href="$t('sidebar.imprintLink')"
          class="sidebar-navigation--footer-item"
        >
          {{ $t("sidebar.imprint") }}
        </a>
        <button
          class="sidebar-navigation--footer-item"
          onclick="UC_UI.showSecondLayer()"
        >
          {{ $t("sidebar.cookies") }}
        </button>
        <a
          :href="$t('sidebar.privacyLink')"
          class="sidebar-navigation--footer-item"
        >
          {{ $t("sidebar.privacy") }}
        </a>
      </footer>
    </nav>
    <div class="sidebar-navigation--backdrop" @click="$emit('close')" />
  </div>
</template>

<style>
.sidebar-navigation {
  --sidebar-navigation-inline-padding: var(--spacing-24);
  --sidebar-animation-duration: 0.2s;

  position: fixed;
  z-index: var(--z-index-sidebar);
  inset: 0;
  transform: translateX(-100%);
  transition: transform 0s var(--sidebar-animation-duration);

  &.is-open {
    transform: translateX(0);
    transition: unset;
  }
}

.sidebar-navigation--backdrop {
  opacity: 0;
  position: absolute;
  inset: 0;
  background-color: var(--color-background-transparent);
  transition: opacity var(--sidebar-animation-duration);

  .sidebar-navigation.is-open & {
    opacity: 1;
  }
}

.sidebar-navigation--nav {
  opacity: 0;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: var(--z-index-default);
  max-inline-size: calc(min(100% - 60px, var(--sidebar-navigation-width)));
  block-size: 100%;
  transform: translateX(-100%);
  background-color: var(--color-background-light);
  transition:
    transform var(--sidebar-animation-duration),
    opacity var(--sidebar-animation-duration);

  .sidebar-navigation.is-open & {
    opacity: 1;
    transform: translateX(0);
  }
}

.sidebar-navigation--logo {
  padding-inline: var(--sidebar-navigation-inline-padding);
  padding-block-start: var(--spacing-24);
  padding-block-end: var(--spacing-80);
  background-color: var(--color-primary);
}

.sidebar-navigation--info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  padding-block: var(--spacing-24);
  padding-inline: var(--sidebar-navigation-inline-padding);
  background-color: var(--color-background-neutral-lightest);
}

.sidebar-navigation--nav-list {
  flex: 1;
  padding-block: var(--spacing-24);
  padding-inline: var(--sidebar-navigation-inline-padding);
}

.sidebar-navigation--nav-link {
  display: flex;
  align-items: center;
  gap: var(--spacing-8);
  inline-size: 100%;
  padding-block: var(--spacing-12);

  &:focus,
  &:hover,
  &:active {
    text-decoration: none;
    color: var(--color-primary);
  }

  &[aria-current="page"] {
    color: var(--color-primary);
  }
}

.sidebar-navigation--footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-24) var(--spacing-16);
  padding-block: var(--spacing-24);
  padding-inline: var(--sidebar-navigation-inline-padding);
}

.sidebar-navigation--footer-item {
  -webkit-font-smoothing: antialiased;
  color: var(--color-text-mid-light);
}
</style>
