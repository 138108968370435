import { ScaleStatus, BadgeStatus } from "@/types";

export function scaleToBadgeStatus(scaleStatus: ScaleStatus) {
  switch (scaleStatus) {
    case ScaleStatus.Pending:
      return BadgeStatus.Neutral;
    case ScaleStatus.Loading:
      return BadgeStatus.Info;
    case ScaleStatus.VehicleReady:
    case ScaleStatus.OrderReady:
      return BadgeStatus.Success;
    case ScaleStatus.VehicleOverloaded:
    case ScaleStatus.OrderOverloaded:
      return BadgeStatus.Error;
    default:
      return BadgeStatus.Neutral;
  }
}
