<script setup lang="ts">
import { useRouter } from "vue-router";
import { ref, computed, watch } from "vue";
import { useForm } from "vee-validate";
import { BlankLayout } from "@/layouts";
import { PfrForm, FieldInput, PfrButton, LanguageSwitcher } from "@/components";
import { useAuthStore } from "@/stores/auth";
import { ButtonElement } from "@/types";

interface LoginFields {
  email: string;
  registrationNumber: string;
  password: string;
}

const authSuccess = ref<boolean | null>(null);
const authFailureMessage = ref<String | null>(null);

const authStore = useAuthStore();
const router = useRouter();

const validationSchema = {
  email: "required",
  password: "required",
};

const {
  isSubmitting,
  handleSubmit,
  values,
  meta: formMeta,
} = useForm<LoginFields>({
  validationSchema,
});

const onSubmit = handleSubmit(async ({ email, password }) => {
  const { success, message, legalUrl } = await authStore.login(email, password);
  authSuccess.value = success;
  if (!authSuccess.value) {
    authFailureMessage.value = message;
  }
  if (authSuccess.value) {
    if (legalUrl) {
      window.location.href = legalUrl;
    } else {
      router.push("/driver-information");
    }
  }
});

const isAuthErrored = computed(() => authSuccess.value === false);
watch(
  () => values,
  () => {
    authSuccess.value = null;
  },
  { deep: true },
);
</script>

<template>
  <BlankLayout>
    <pfreundt-logo-card
      card-title="Load & Go"
      :card-hint="$t('login.hint')"
      :lang="$i18n.locale"
    >
      <PfrForm @submit-form="onSubmit">
        <FieldInput
          field-name="email"
          :label="$t('login.email')"
          :is-required="true"
          :has-error="isAuthErrored"
        />

        <FieldInput
          field-name="password"
          :label="$t('login.password')"
          :is-required="true"
          :has-error="isAuthErrored"
          type="password"
        />

        <PfrButton
          :is="ButtonElement.Button"
          :disabled="isSubmitting || !formMeta.valid"
          :full-width="true"
          type="submit"
        >
          {{ $t("login.signIn") }}
        </PfrButton>

        <div v-if="authStore.forcedLogout" class="login--info">
          {{ $t("login.forcedLogout") }}
        </div>

        <span v-if="isAuthErrored" class="login--error" aria-live="assertive">
          {{ authFailureMessage ?? $t("login.error") }}
        </span>
      </PfrForm>
    </pfreundt-logo-card>

    <pfreundt-login-footer :lang="$i18n.locale" language-select-slot>
      <LanguageSwitcher :inverted="true" />
    </pfreundt-login-footer>
  </BlankLayout>
</template>

<style>
.login--info,
.login--error {
  display: inline-block;
  margin-block-start: var(--spacing-16);
}

.login--error {
  color: var(--color-error);
}
</style>
