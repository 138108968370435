import { sendRequest, Methods } from "@/services/request";

interface SaveSignatureData {
  signature: Blob;
  description: string;
  dateTime: Date;
  role: "driver" | "customer";
}

const buildSaveSignatureBody = (data: SaveSignatureData) => {
  const form = new FormData();
  form.append("signature[record_date_time]", data.dateTime.toISOString());
  form.append("signature[role]", data.role);
  form.append("signature[description]", data.description);
  form.append("signature[image]", data.signature, "signature.png");

  return form;
};

export const saveSignature = async (
  weighingId: string,
  data: SaveSignatureData,
) => {
  const path = `de/weighings/${weighingId}/signatures`;
  const method = Methods.POST;

  try {
    const response = await sendRequest({
      path,
      method,
      body: buildSaveSignatureBody(data),
    });

    return response.status === 201;
  } catch {
    return false;
  }
};
