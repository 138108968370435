import { setCSRFToken, getCSRFToken } from "@/helpers/authHelper";

interface SendRequestOptions {
  path: string;
  method: Methods;
  headers?: Record<string, string>;
  body?: FormData | object;
}

const buildBody = (body: FormData | object | undefined) => {
  if (body instanceof FormData || body === undefined) return body;
  return JSON.stringify(body);
};

export const sendRequest = async (options: SendRequestOptions) => {
  const baseURL = `${import.meta.env.VITE_WEBPORTAL_URL}/`;

  let headers: Record<string, string> = {
    Accept: "application/json",
    "X-CSRF-TOKEN": getCSRFToken() || "",
    ...(options.headers ? options.headers : {}),
  };

  // fetch adds form data headers by itself
  if (!(options.body instanceof FormData)) {
    headers = {
      ...headers,
      "Content-Type": "application/json",
    };
  }

  const response = await fetch(`${baseURL}${options.path}`, {
    method: options.method,
    headers,
    body: buildBody(options.body),
    credentials: "include",
  });

  setCSRFToken(response.headers.get("X-CSRF-TOKEN"));

  return response;
};

export enum Methods {
  PATCH = "PATCH",
  POST = "POST",
  DELETE = "DELETE",
  GET = "GET",
} // currently needed methods, add methods if necessary
