export default {
  changeData: "Daten ändern",
  closedOrders: "@:views.closedOrders.heading",
  cookies: "Cookies",
  imprint: "Impressum",
  imprintLink: "https://www.pfreundt.de/impressum",
  licensePlate: "Fahrzeugkennzeichen",
  linkSharing: "Linkfreigabe",
  logout: "Abmelden",
  menu: "Menü öffnen",
  myOrders: "@:views.myOrders.heading",
  openOrders: "@:views.openOrders.heading",
  privacy: "Datenschutz",
  privacyLink: "https://www.pfreundt.de/datenschutz",
  userName: "Username",
};
