export default {
  currentWeight: "Aktuelles Gewicht",
  status: {
    pending: "Warten auf Beladung",
    loading: "Beladungsvorgang aktiv",
    vehicleReady: "Fahrzeugkapazität erreicht",
    orderReady: "Zielgewicht des Auftrags erreicht",
    vehicleOverloaded: "Fahrzeugkapazität überschritten",
    orderOverloaded: "Zielgewicht des Auftrags überladen",
  },
  targetWeight: "von {weight} {material}",
};
