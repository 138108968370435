import type { UserCentricsCustomEvent } from "@/types";

export const setupUserCentricsBanner = (
  onUCSettingsChanged: (event: UserCentricsCustomEvent) => void,
) => {
  if (
    import.meta.env.VITE_USERCENTRICS_ENABLED !== "true" ||
    !import.meta.env.VITE_USERCENTRICS_SETTINGS_ID
  )
    return;

  const tag = document.createElement("script");
  tag.setAttribute(
    "src",
    "https://app.usercentrics.eu/browser-ui/latest/loader.js",
  );
  tag.setAttribute("id", "usercentrics-cmp");
  tag.setAttribute(
    "data-settings-id",
    import.meta.env.VITE_USERCENTRICS_SETTINGS_ID,
  );
  tag.setAttribute("async", "");

  document.head.appendChild(tag);

  window.addEventListener("ucSettingsChanged", (event) =>
    onUCSettingsChanged(event as UserCentricsCustomEvent),
  );
};
