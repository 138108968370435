<script setup lang="ts">
import { computed, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { PageLayout } from "@/layouts";
import {
  OrderIndexMain,
  LoadingStateDisplay,
  SkeletonOrderIndex,
  PfrButton,
} from "@/components";
import {
  OrderState,
  LoadingState,
  ButtonElement,
  ButtonSize,
  RouteName,
} from "@/types";
import { useOrdersStore, useUserStore } from "@/stores";
import { useI18n } from "vue-i18n";

const ordersStore = useOrdersStore();
const { fetchOrders } = ordersStore;
const { orders: orderItems, fetching } = storeToRefs(ordersStore);

const userStore = useUserStore();
const { vehicleId } = storeToRefs(userStore);

const { t } = useI18n();

const isEmpty = computed(() => orderItems.value.length === 0);

onMounted(() => {
  fetchOrders([OrderState.Open], vehicleId.value, "");
});

const search = (searchValue: string) => {
  fetchOrders([OrderState.Open], vehicleId.value, searchValue);
};
</script>

<template>
  <PageLayout :heading="t('views.myOrders.heading')" @search="search">
    <template #main>
      <LoadingStateDisplay
        :loading-state="fetching ? LoadingState.Loading : LoadingState.Success"
      >
        <template #loading>
          <SkeletonOrderIndex :is-closed="true" />
        </template>
        <template #default>
          <div v-if="isEmpty" class="no-orders-hint">
            <pfreundt-heading
              tag="h2"
              size="xsmall"
              class="no-orders-hint--title"
              centered
              balanced
            >
              {{ t("views.myOrders.noOrdersHint") }}
            </pfreundt-heading>
            <img
              class="no-orders-hint--badge"
              src="@/assets/images/noOrdersBadge.svg"
            />
          </div>
          <OrderIndexMain v-else :combined-orders="orderItems" />
        </template>
      </LoadingStateDisplay>
    </template>
    <template v-if="isEmpty" #mainFooter>
      <PfrButton
        :is="ButtonElement.RouterLink"
        :size="ButtonSize.Large"
        :full-width="true"
        :to="{ name: RouteName.OpenOrders }"
      >
        {{ t("views.openOrders.heading") }}
      </PfrButton>
    </template>
  </PageLayout>
</template>

<style>
.slide-fade-enter-active {
  transition: all 0.2s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  opacity: 0;
  transform: translateX(20px);
}

.no-orders-hint {
  --badge-inline-size: 162px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-32);
  margin-block-start: var(--spacing-32);
}

.no-orders-hint--badge {
  inline-size: var(--badge-inline-size);
  aspect-ratio: 1;
}
</style>
