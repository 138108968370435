export default {
  heading: "Order {id}",
  blanketOrderHeading: "Blanket order {id}",
  hint: "{count} Order | {count} Orders",
  status: {
    inLoading: "In Loading",
    ownOrder: "My Order",
    paused: "Paused",
  },
  card: {
    details: {
      address: "Address",
      material: "Material",
      customer: "Customer",
      total: "Total",
      signed: "Already signed",
    },
    delivery: {
      distance: "Distance",
      infos: "Deliveryinfos",
      address: "Address",
      deliveryTill: "Delivery till {date}",
      date: "Delivery Date",
      driver: "Driver",
      licencePlate: "Licence Plate",
    },
    openDeliveryNote: "Open Delivery Notes",
  },
};
