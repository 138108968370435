<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path
      d="
        M16 3a13 13 0 1 0 0 26 13 13 0 0 0 12.3-17l-1.6 1.5A11 11 0 0 1 16
        27 11 11 0 0 1 5 16a11 11 0 0 1 18.6-8L25 6.8A12.7 12.7 0 0 0 16 3Zm11.3
        4.3L16 18.6l-4.3-4.3-1.4 1.4 5 5 .7.7.7-.7 12-12Z
      "
    />
  </svg>
</template>
