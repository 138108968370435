import PhotoSwipeLightbox from "photoswipe/lightbox";
import { useField } from "vee-validate";
import { computed, nextTick, onUnmounted, ref } from "vue";

type ImageFile = {
  file: File;
  url: string;
};

export const useImageUpload = (fieldName: string) => {
  const lightbox = ref<PhotoSwipeLightbox | null>(null);

  const {
    errorMessage: fieldError,
    meta,
    handleBlur,
    handleChange,
    value: images,
  } = useField<ImageFile[]>(fieldName, undefined, {
    initialValue: [],
  });

  const initializeLightbox = () => {
    if (lightbox.value) {
      return;
    }

    lightbox.value = new PhotoSwipeLightbox({
      gallerySelector: ".preview",
      childSelector: ".preview-image",
      pswpModule: () => import("photoswipe"),
    });

    lightbox.value.init();
  };

  const onFileChange = (event: Event) => {
    const target = event.target as HTMLInputElement;
    const newFiles = Array.from(target.files as FileList);

    const newImages = newFiles.map((file) => ({
      file,
      url: URL.createObjectURL(file),
    }));

    // Assign the new images to the images ref
    handleChange([...images.value, ...newImages]);

    nextTick(() => {
      initializeLightbox();
    });
  };

  const errorMessage = computed(() => {
    if (!meta.touched) return null;

    return fieldError?.value ?? "";
  });

  const deleteImage = (imageUrl: string) => {
    URL.revokeObjectURL(imageUrl);
    images.value = images.value.filter((image) => image.url !== imageUrl);

    if (images.value.length <= 0) {
      lightbox.value?.destroy();
      lightbox.value = null;
    }
  };

  const onImageLoad = (event: Event) => {
    const image = event.target as HTMLImageElement;
    const linkElement = image.closest("a");

    if (!linkElement) return;

    linkElement.dataset.pswpHeight = image.naturalHeight.toString();
    linkElement.dataset.pswpWidth = image.naturalWidth.toString();
  };

  onUnmounted(() => {
    images.value.forEach((image) => {
      URL.revokeObjectURL(image.url);
    });

    lightbox.value?.destroy();
  });

  return {
    deleteImage,
    onFileChange,
    handleBlur,
    onImageLoad,
    errorMessage,
    images,
  };
};
