<script setup lang="ts">
import PfrButton from "@/components/generic/PfrButton/PfrButton.component.vue";
import {
  ButtonElement,
  ButtonVariant,
  IconName,
  type ExtendedOrderItem,
  type BlanketOrder,
  OrderState,
} from "@/types";
import LinkCard from "@/components/generic/LinkCard/LinkCard.component.vue";
import { useFormattedOrderItem } from "@/composables";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import GenericOrderDetailList from "../GenericOrderDetailList/GenericOrderDetailList.component.vue";
import { useCardLinks } from "./CardLinks.composable";

interface Props {
  entry: ExtendedOrderItem | BlanketOrder;
  withoutLink?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  withoutLink: false,
});

const { formattedOrderItem } = useFormattedOrderItem(props.entry);

const { t } = useI18n();

const title = computed(() => {
  if (props.entry.type === "blanketOrder") {
    return t("order.blanketOrderHeading", {
      id: formattedOrderItem.value.number,
    });
  }
  return t("order.heading", { id: formattedOrderItem.value.number });
});

const { detailsLink, deliveryNoteLink } = useCardLinks(props.entry);

const showDeliveryNoteButton = computed(
  () => props.entry.type === "order" && props.entry.state === OrderState.Closed,
);
</script>

<template>
  <LinkCard :title="title" :link="withoutLink ? undefined : detailsLink">
    <template #default>
      <div class="pfreundt-stack">
        <GenericOrderDetailList :order-item="formattedOrderItem" />
        <PfrButton
          :is="ButtonElement.RouterLink"
          v-if="showDeliveryNoteButton"
          :variant="ButtonVariant.Secondary"
          :to="deliveryNoteLink"
          :icon="IconName.File"
          :full-width="true"
        >
          {{ $t("order.card.openDeliveryNote") }}
        </PfrButton>
      </div>
    </template>
  </LinkCard>
</template>
