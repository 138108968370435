import {
  type Consumer,
  type ChannelNameWithParams,
  createConsumer,
} from "@rails/actioncable";
import { Observable } from "zen-observable-ts";
import { type DocumentNode, Kind } from "graphql";
import { CombinedError, type SubscriptionOperation } from "urql";

// based on https://github.com/rmosolgo/graphql-ruby/blob/master/javascript_client/src/subscriptions/ActionCableLink.ts
// and https://github.com/FormidableLabs/urql/issues/787
class ActionCableExchange {
  cable: Consumer;

  channelName: string;

  actionName: string;

  connectionParams: object;

  constructor(options: {
    cable: Consumer;
    channelName?: string;
    actionName?: string;
    connectionParams?: object;
  }) {
    this.cable = options.cable;
    this.channelName = options.channelName || "GraphqlChannel";
    this.actionName = options.actionName || "execute";
    this.connectionParams = options.connectionParams || {};
  }

  // eslint-disable-next-line
  getOperationName(query: DocumentNode): string | undefined {
    for (let i = 0, l = query.definitions.length; i < l; i += 1) {
      const node = query.definitions[i];
      if (node.kind === Kind.OPERATION_DEFINITION && node.name) {
        return node.name.value;
      }
    }

    return undefined;
  }

  request(operation: SubscriptionOperation) {
    return new Observable((observer) => {
      const channelId = Math.round(
        Date.now() + Math.random() * 100000,
      ).toString(16);
      const { actionName } = this;
      const { operationName } = operation;
      const subscription = this.cable.subscriptions.create(
        {
          channel: this.channelName,
          channelId,
          ...this.connectionParams,
        },
        {
          connected() {
            this.perform(actionName, {
              query: operation.query ? operation.query : null,
              variables: operation.variables,
              // TODO: add this to support persisted queries.
              // But we need to get the operationName at first
              // operationId: (operation as { operationId?: string }).operationId,
              operationName,
            });
          },
          received(payload: ChannelNameWithParams) {
            if (payload.result.data || payload.result.errors) {
              observer.next(payload.result);
            }

            if (!payload.more) {
              observer.complete();
            }
          },
          disconnected() {
            const err = new CombinedError({
              networkError: Error("WS disconnected"),
            });
            observer.error(err);
          },
        },
      );

      // Make the ActionCable subscription behave like an Apollo subscription
      return Object.assign(subscription, { closed: false });
    });
  }
}

const cable = createConsumer(import.meta.env.VITE_ACTION_CABLE_URL);
const actionCableClient = new ActionCableExchange({ cable });

export { actionCableClient };
