<script setup lang="ts">
import { RouterLink, type RouteLocationRaw } from "vue-router";
import Icon from "@/components/generic/Icon/Icon.component.vue";
import { IconName } from "@/types";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

interface Props {
  icon?: IconName;
  to: RouteLocationRaw;
  ariaLabel?: string;
}

withDefaults(defineProps<Props>(), {
  icon: IconName.AngleLeft,
  ariaLabel: undefined,
});
</script>

<template>
  <RouterLink :to="to" :aria-label="ariaLabel ?? t('navigation.back')">
    <Icon :icon="icon" />
  </RouterLink>
</template>
