import { createRouter, createWebHistory, type Router } from "vue-router";
import {
  ClosedDeliveryNoteView,
  ClosedOrdersView,
  ConfirmArrivalView,
  DeliveryConfirmView,
  DeliveryNotesView,
  DeliverySummaryView,
  LoadingStatusView,
  LoginView,
  MyOrdersView,
  OpenOrdersView,
  OrderShowView,
  SelectDriverInformationView,
  SelectOrderDetailsView,
  WeighingConfirmationView,
} from "@/views";
import { RouteName } from "@/types";
import { extractCredentials, extractParams } from "@/helpers";
import { useAuthStore, useUserStore } from "@/stores";
import { requestCSRFToken, signIn } from "@/services";
import { inject } from "vue";
import { useMixpanel } from "@/composables/mixpanel";
import { type Mixpanel } from "mixpanel-browser";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: RouteName.OpenOrders,
      component: OpenOrdersView,
      meta: {
        isAuthRequired: true,
        mixpanelName: "Load&Go Index Orders",
      },
    },
    {
      path: "/orders/closed",
      name: RouteName.ClosedOrderIndex,
      component: ClosedOrdersView,
      meta: {
        isAuthRequired: true,
        mixpanelName: "Load&Go Index Closed Orders",
      },
    },
    {
      path: "/my-orders",
      name: RouteName.MyOrders,
      component: MyOrdersView,
      meta: {
        isAuthRequired: true,
      },
    },
    {
      path: "/driver-information",
      name: RouteName.SelectDriverInformation,
      component: SelectDriverInformationView,
      meta: {
        isAuthRequired: true,
        mixpanelName: "Load&Go Select driver information",
      },
    },
    {
      path: "/orders/:orderId/items/:itemId/confirm-arrival",
      name: RouteName.ConfirmArrival,
      component: ConfirmArrivalView,
      meta: {
        isAuthRequired: true,
      },
      props: (route) => extractParams(route.params, ["orderId", "itemId"]),
    },
    {
      path: "/blanket-orders/:blanketOrderId",
      name: RouteName.SelectOrderDetails,
      component: SelectOrderDetailsView,
      meta: {
        isAuthRequired: true,
      },
      props: (route) => extractParams(route.params, ["blanketOrderId"]),
    },
    {
      path: "/orders/:orderId/items/:itemId",
      name: RouteName.OrderShow,
      component: OrderShowView,
      meta: {
        isAuthRequired: true,
        mixpanelName: "Load&Go Show Order",
      },
      props: (route) => extractParams(route.params, ["orderId", "itemId"]),
    },
    {
      path: "/orders/:orderId/items/:itemId/loading",
      name: RouteName.Loading,
      component: LoadingStatusView,
      meta: {
        isAuthRequired: true,
        mixpanelName: "Load&Go Loading status",
      },
      props: (route) => extractParams(route.params, ["orderId", "itemId"]),
    },
    {
      path: "/orders/:orderId/items/:itemId/weighings/:weighingId/confirm",
      name: RouteName.WeighingConfirmation,
      component: WeighingConfirmationView,
      meta: {
        isAuthRequired: true,
        mixpanelName: "Load&Go Weighing confirmation",
      },
      props: (route) =>
        extractParams(route.params, ["orderId", "itemId", "weighingId"]),
    },
    {
      path: "/orders/:orderId/items/:itemId/weighings/:weighingId/delivery",
      name: RouteName.DeliverySummary,
      component: DeliverySummaryView,
      meta: {
        isAuthRequired: true,
        mixpanelName: "Load&Go Delivery summary",
      },
      props: (route) =>
        extractParams(route.params, ["orderId", "itemId", "weighingId"]),
    },
    {
      path: "/orders/:orderId/items/:itemId/weighings/:weighingId/delivery-confirm",
      name: RouteName.DeliveryConfirm,
      component: DeliveryConfirmView,
      meta: {
        isAuthRequired: true,
        mixpanelName: "Load&Go Delivery confirm",
      },
      props: (route) =>
        extractParams(route.params, ["orderId", "itemId", "weighingId"]),
    },
    {
      path: "/orders/:orderId/items/:itemId/weighings/:weighingId/closed-delivery-note",
      name: RouteName.ClosedDeliveryNote,
      component: ClosedDeliveryNoteView,
      meta: {
        isAuthRequired: true,
        mixpanelName: "Load&Go Closed delivery note",
      },
      props: (route) =>
        extractParams(route.params, ["orderId", "itemId", "weighingId"]),
    },
    {
      path: "/login",
      name: RouteName.Login,
      component: LoginView,
      meta: {
        urlLogin: false,
        isAuthRequired: false,
        redirectIfAuthenticated: true,
        mixpanelName: "Load&Go Login",
      },
    },
    {
      path: "/urllogin",
      name: RouteName.URLLogin,
      component: LoginView,
      meta: {
        isAuthRequired: false,
        urlLogin: true,
        redirectIfAuthenticated: true,
      },
    },
    {
      path: "/orders/:orderId/items/:itemId/delivery-notes",
      name: RouteName.DeliveryNoteIndex,
      component: DeliveryNotesView,
      meta: {
        isAuthRequired: true,
        mixpanelName: "Delivery notes",
      },
      props: (route) => extractParams(route.params, ["orderId", "itemId"]),
    },
    {
      path: "/:pathMatch(.*)*",
      redirect: { name: RouteName.Login },
    },
  ],
});

router.afterEach((to, from, failure) => {
  const { trackPageView } = useMixpanel(inject<Mixpanel>("mixpanel"));
  if (!failure)
    trackPageView((to.meta.mixpanelName ?? "Missing name") as string);
});

const setupAuthGuard = (r: Router) => {
  r.beforeEach(async (to, from) => {
    const authStore = useAuthStore();
    if (!authStore.loggedIn) {
      // It is not possible to find out whether the user is logged in,
      // neither via the local storage nor via one of the stores.
      // With every Graphql query the user is authenticated.
      // Therefore it is possible to find out via the user query if the user is already logged in.
      // The authStore.loggedIn used later is set automatically via the Graphql queries.
      // See setup/graphql/index.ts
      await requestCSRFToken();
      const logoutState = authStore.forcedLogout;
      const userStore = useUserStore();
      await userStore.fetchUser();
      authStore.forcedLogout = logoutState;
    }
    if (to.meta.isAuthRequired === undefined)
      throw Error("always define auth requirements in routes!");
    // avoid auth guard for hash routing
    if (to.name === from.name) return true;

    if (to.meta.redirectIfAuthenticated && authStore.loggedIn) {
      return {
        name: RouteName.SelectDriverInformation,
      };
    }
    if (to.meta.isAuthRequired && !authStore.loggedIn) {
      return {
        name: RouteName.Login,
      };
    }
    if (to.meta.urlLogin && !authStore.loggedIn) {
      const [email, password] = extractCredentials(window.location.search);
      await signIn(email, password);

      return {
        name: RouteName.SelectDriverInformation,
      };
    }
    return true;
  });
};

setupAuthGuard(router);

export { router };
