<script setup lang="ts">
import { computed, type PropType } from "vue";
import { IconName } from "@/types";
import Icon from "@/components/generic/Icon/Icon.component.vue";
import FieldSelect from "@/components/generic/FieldSelect/FieldSelect.component.vue";
import { useI18n } from "vue-i18n";

const LANGUAGES = ["de", "en"];

const { t } = useI18n();

const options = computed(() =>
  LANGUAGES.map((language) => ({
    value: language,
    label: t(`components.languageSwitcher.languages.${language}`),
  })),
);

const props = defineProps({
  inverted: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
});

const languageSwitcherClasses = computed(() => [
  props.inverted ? "is-inverted" : "",
]);
</script>

<template>
  <div class="language-switcher" :class="languageSwitcherClasses">
    <Icon v-if="!inverted" :icon="IconName.Language" />

    <FieldSelect
      :id="`a11y-language-switcher`"
      v-model="$i18n.locale"
      :is-required="true"
      :class="inverted || 'flex-1'"
      :inverted="inverted"
      :options="options"
      :hidden-label="true"
      :sync-v-model="true"
      :label="$t('components.languageSwitcher.label')"
      field-name="a11y-locale"
      :value="$i18n.locale"
    />
  </div>
</template>

<style>
.language-switcher {
  display: inline-block;

  &:not(.is-inverted) {
    display: flex;
    align-items: center;
    gap: var(--spacing-8);
    inline-size: 100%;
  }
}
</style>
