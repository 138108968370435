import { useRouter } from "vue-router";
import { OrderState, RouteName } from "@/types";

interface FirstStepLinkProps {
  orderId: string;
  itemId: string;
  orderState?: OrderState;
}

interface OverviewLinkProps {
  userVehicleId?: string | null;
  orderVehicleId?: string;
  orderState?: OrderState;
}

export const useBackLink = () => {
  const router = useRouter();

  const overviewLink = ({
    userVehicleId,
    orderVehicleId,
    orderState,
  }: OverviewLinkProps) => {
    if (orderState === OrderState.Closed)
      return { name: RouteName.ClosedOrderIndex };

    if (!userVehicleId || !orderVehicleId)
      return { name: RouteName.OpenOrders };

    return orderVehicleId === userVehicleId
      ? { name: RouteName.MyOrders }
      : { name: RouteName.OpenOrders };
  };

  const firstStepLink = ({
    orderId,
    itemId,
    orderState,
  }: FirstStepLinkProps) => {
    if (
      orderState === OrderState.Closed &&
      router.currentRoute.value.name === RouteName.DeliveryNoteIndex
    ) {
      return { name: RouteName.ClosedOrderIndex };
    }

    const name =
      orderState === OrderState.Closed
        ? RouteName.DeliveryNoteIndex
        : RouteName.OrderShow;

    return {
      name,
      params: {
        orderId,
        itemId,
      },
    };
  };

  return { overviewLink, firstStepLink };
};
