import { defineStore } from "pinia";
import { useGetDriversQuery } from "@/types/graphql";
import type { Driver } from "@/types";
import { useI18n } from "vue-i18n";

interface DriversStore {
  drivers: Array<Driver>;
}

export const useDriversStore = defineStore("drivers", {
  state: (): DriversStore => ({
    drivers: [],
  }),
  getters: {
    driverOptions: (state) =>
      state.drivers.map((driver) => ({
        label:
          driver.name ||
          useI18n().t("views.selectDriverInformation.noDriverName"),
        value: driver.id,
      })),
  },
  actions: {
    async fetchDrivers() {
      const { data } = await useGetDriversQuery({ variables: {} });

      if (data.value?.drivers?.nodes) {
        this.drivers = data.value.drivers.nodes;
      }
    },
  },
});
