<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path
      d="
        m16 4.4-1 2.2-8 19-.8 2.1 2.1-.8 7.7-2.8 7.7 2.8 2.1.9-.9-2.2-8-19Zm0
        5.1 6.2 14.8-5.9-2.2-.3-.2-.3.2-5.9 2.1Z
      "
    />
  </svg>
</template>
