export default {
  cancelOrder: {
    confirm: "Cancel order",
    hint: 'The order can be found under "@:openOrders.heading".',
    title: "Cancel order {orderNumber}?",
    success: "The order {orderNumber} has been canceled.",
  },
  imageUpload: {
    takePhoto: "Take photo",
    photoPreview: "Preview photo",
    deletePreviewPhoto: "Delete photo",
  },
  languageSwitcher: {
    label: "Language",
    languages: {
      de: "German",
      en: "English",
    },
  },
  loadingStateDisplay: {
    error: "Something went wrong :(",
    noInternetConnection: "Loading without Internet",
    noInternetInfo:
      "You can load as usual. As soon as the Internet connection is restored, the weighing data will be transmitted automatically and you will have access to the delivery bill.",
    reload: "Reload",
  },
  search: {
    label: "Search",
    cancel: "Cancel",
    placeholder: "Order number / customer",
  },
  signature: {
    open: "Open Signature Field",
    delete: "Delete Signature",
  },
};
