<script setup lang="ts">
import { computed } from "vue";
import type { RouteLocationRaw } from "vue-router";
import Icon from "@/components/generic/Icon/Icon.component.vue";
import { IconName, IconSize } from "@/types";

interface Props {
  title: string;
  link: RouteLocationRaw | undefined;
}
const props = defineProps<Props>();

const component = computed(() => (!props.link ? "div" : "RouterLink"));
</script>

<template>
  <component
    :is="component"
    :to="!props.link ? undefined : link"
    class="link-card"
    :class="{ 'is-interactable': !!props.link }"
    data-testid="link-card"
  >
    <div class="link-card--header">
      <strong class="link-card--title">
        {{ title }}
      </strong>
    </div>
    <slot />

    <Icon
      v-if="!!props.link"
      :icon="IconName.AngleRight"
      :size="IconSize.Small"
      class="link-card--chevron"
    />
  </component>
</template>

<style>
.link-card {
  --link-card-animation-duration: 0.2s;

  display: block;
  position: relative;
  border-radius: 4px;
  padding: var(--spacing-24);
  box-shadow: var(--elevation-8);
  background-color: var(--color-background-light);
  transition: box-shadow var(--link-card-animation-duration);

  &.is-interactable {
    text-decoration: none;

    &:focus,
    &:hover,
    &:active {
      box-shadow: var(--elevation-13);
    }
  }
}

.link-card--chevron {
  position: absolute;
  inset-block-start: 50%;
  inset-inline-end: var(--spacing-16);
  transform: translateY(-50%);
  transition: inset-inline-end var(--link-card-animation-duration);

  .link-card.is-interactable:focus &,
  .link-card.is-interactable:hover &,
  .link-card.is-interactable:active & {
    inset-inline-end: calc(var(--spacing-16) - 2px);
    color: var(--color-primary);
  }
}

.link-card--header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block-end: var(--spacing-16);
}

.link-card--title {
  @apply text-18/18;

  font-weight: 700;
}
</style>
