export default {
  closedOrderShow: {
    heading: "Auftragsübersicht",
  },
  closedOrders: {
    heading: "Abgeschlossene Aufträge",
  },
  confirmArrival: {
    heading: "Ankunft bestätigen",
    description: "Sind Sie am Material-Standort angekommen?",
    failedToFetchOrder: "Auftragsdaten konnten nicht geladen werden",
    confirm: "@:views.confirmArrival.heading",
  },
  deliverySummary: {
    heading: "Lieferung",
    instruction: "Fahre los zum Kunden, um die Lieferung abzuladen.",
    startRoute: "Route Starten",
    openDeliveryNote: "Lieferschein öffnen",
    details: {
      dateTime: "Lieferung bis {date} {time}",
    },
  },
  loadingStatus: {
    heading: "Beladung",
    description:
      "Bitte bewege das Fahrzeug während der Beladung nicht und bleibe zur Sicherheit im Fahrerhaus.",
    call: " Dispo anrufen",
    ignoreOverload: " Überladung ignorieren",
    confirm: "Wiegedaten bestätigen",
  },
  myOrders: {
    heading: "Meine Aufträge",
    noOrdersHint: "Füge aus den offenen Aufträgen neue Aufträge hinzu.",
  },
  openOrders: {
    heading: "Offene Aufträge",
  },
  orderDetails: {
    heading: "Auftragsdetails",
    start: "Auftrag starten",
    continue: "Auftrag fortsetzen",
    startLoading: "Zur Beladung",
    nextLoading: "Weitere Beladung",
    started: "@:order.heading gestartet",
    showDeliveryReceipts: "Lieferscheine anzeigen",
  },
  selectDeliveryNote: {
    heading: "Lieferschein auswählen",
    instruction:
      "Bitte wähle den richtigen Lieferschein aus, um die Wiegedaten zu bestätigen.",
    confirm: "Wiegedaten bestätigen",
  },
  selectDriverInformation: {
    noLicensePlate: "Kein Kennzeichen",
    noDriverName: "Kein Fahrername",
    driverInformation: "Fahrerinformationen",
    heading: "Bitte geben Sie folgende Daten ein",
    account: "Account",
    drivername: "Fahrername",
    vehicle: "Fahrzeug",
    confirmData: "Daten bestätigen",
  },
  selectOrderDetails: {
    heading: "@:views.orderDetails.heading auswählen",
    hint: "Bitte geben Sie folgende Daten ein",
    material: "Material",
    targetWeight: "Zielgewicht in {unit}",
    startOrder: "Auftrag starten",
  },
  weighingConfirmation: {
    heading: "Wiegedaten bestätigen",
    confirmMessage: "Wiegedaten bestätigt",
  },
};
