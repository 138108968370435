import components from "./de/components";
import deliveryNote from "./de/delivery-note";
import forms from "./de/forms";
import login from "./de/login";
import navigation from "./de/navigation";
import order from "./de/order";
import request from "./de/request";
import scale from "./de/scale";
import sidebar from "./de/sidebar";
import units from "./de/units";
import views from "./de/views";

export const de = {
  deliveryNote,
  forms,
  login,
  navigation,
  order,
  request,
  scale,
  sidebar,
  units,
  views,
  components,
};
