import { ref } from "vue";

export const useSearch = () => {
  const searchValue = ref("");
  const searchOpen = ref(false);

  const closeSearch = () => {
    searchOpen.value = false;
    searchValue.value = "";
  };

  const openSearch = () => {
    searchOpen.value = true;
  };

  return {
    searchValue,
    searchOpen,
    closeSearch,
    openSearch,
  };
};
