<script setup lang="ts">
import { computed, type PropType } from "vue";
import { useI18n } from "vue-i18n";
import { format } from "date-fns";
import InfoList from "@/components/generic/InfoList/InfoList.component.vue";
import type { ExtendedWeighing } from "@/types";
import { buildAddress, buildWeight } from "@/helpers";

const props = defineProps({
  weighing: {
    type: Object as PropType<ExtendedWeighing>,
    required: true,
  },
});

const { t } = useI18n();

const deliveryNoteDetailEntries = computed(() => [
  {
    key: t("order.card.details.total"),
    value: props.weighing.material ? buildWeight(props.weighing) : undefined,
  },
  {
    key: t("order.card.details.material"),
    value: props.weighing.material ? props.weighing.material.name : undefined,
  },
  {
    key: t("order.card.details.customer"),
    value: props.weighing.customer ? props.weighing.customer.name : undefined,
  },
  {
    key: t("order.card.delivery.address"),
    value: props.weighing.address
      ? buildAddress(props.weighing.address)
      : undefined,
  },
  {
    key: t("order.card.delivery.date"),
    value: props.weighing.date
      ? format(props.weighing.date, "dd.MM.yyyy, HH:mm")
      : undefined,
  },
  { key: t("order.card.delivery.driver"), value: props.weighing.driver?.name },
  {
    key: t("order.card.delivery.licencePlate"),
    value: props.weighing.vehicle?.licensePlate,
  },
]);
</script>

<template>
  <InfoList :entries="deliveryNoteDetailEntries" />
</template>
