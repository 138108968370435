import { createI18n } from "vue-i18n";
import { locales as messages } from "@/locales";

type MessageSchema = typeof messages.de;

export const buildI18n = () => {
  const i18n = createI18n<[MessageSchema], "de" | "en">({
    // use composition api
    legacy: false,
    // but also inject $t() and others to components
    globalInjection: true,
    locale: "de",
    fallbackLocale: "en",
    messages,
    numberFormats: {
      en: {
        decimal: {
          style: "decimal",
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
          useGrouping: true,
        },
        percent: {
          style: "percent",
          useGrouping: true,
        },
      },
      de: {
        decimal: {
          style: "decimal",
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
          useGrouping: true,
          currency: "EUR", // Add the missing currency property
        },
        percent: {
          style: "percent",
          useGrouping: true,
        },
      },
    },
  });

  return i18n;
};
