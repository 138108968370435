import { ScaleStatus } from "@/types";

const inToleranceRange = (value: number, target: number, tolerance: number) =>
  value >= target - tolerance && value <= target + tolerance;

const getOrderStatus = (
  currentWeight: number,
  orderRemainingWeight: number,
  tolerance: number,
) => {
  if (inToleranceRange(currentWeight, orderRemainingWeight, tolerance)) {
    return ScaleStatus.OrderReady;
  }
  if (currentWeight > orderRemainingWeight) return ScaleStatus.OrderOverloaded;

  return null;
};

const getVehicleStatus = (
  currentWeight: number,
  vehicleCapacity: number | null,
  tolerance: number,
) => {
  if (!vehicleCapacity) return null;
  if (inToleranceRange(currentWeight, vehicleCapacity, tolerance)) {
    return ScaleStatus.VehicleReady;
  }
  if (currentWeight > vehicleCapacity) return ScaleStatus.VehicleOverloaded;

  return null;
};

export const getScaleStatus = (
  currentWeight: number,
  vehicleCapacity: number | null,
  orderRemainingWeight: number,
  tolerance: number,
) => {
  const orderStatus = getOrderStatus(
    currentWeight,
    orderRemainingWeight,
    tolerance,
  );
  const vehicleStatus = getVehicleStatus(
    currentWeight,
    vehicleCapacity,
    tolerance,
  );

  if (vehicleStatus === ScaleStatus.VehicleOverloaded)
    return ScaleStatus.VehicleOverloaded;
  if (orderStatus === ScaleStatus.OrderOverloaded)
    return ScaleStatus.OrderOverloaded;
  if (orderStatus === ScaleStatus.OrderReady) return ScaleStatus.OrderReady;
  if (vehicleStatus === ScaleStatus.VehicleReady)
    return ScaleStatus.VehicleReady;
  if (currentWeight > 0) return ScaleStatus.Loading;

  return ScaleStatus.Pending;
};
