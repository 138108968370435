<script setup lang="ts">
import { useSidebar } from "@/composables";
import { useI18n } from "vue-i18n";
import { IconName } from "@/types";
import { watch } from "vue";
import SidebarNavigation from "../SidebarNavigation/SidebarNavigation.component.vue";
import Icon from "../Icon/Icon.component.vue";

const { isSidebarOpen, closeSidebar, openSidebar } = useSidebar();

const { t } = useI18n();

// prevent a scrollable body if sidebar is open
watch(
  () => isSidebarOpen,
  (newValue) => {
    const bodyElement = document.querySelector("body");
    if (bodyElement) {
      if (newValue) {
        bodyElement.style.overflow = "hidden";
      } else {
        bodyElement.style.removeProperty("overflow");
      }
    }
  },
);
</script>

<template>
  <button :aria-label="t('sidebar.menu')" @click="openSidebar">
    <Icon :icon="IconName.Bars" />
  </button>

  <Teleport to="body">
    <SidebarNavigation :is-open="isSidebarOpen" @close="closeSidebar" />
  </Teleport>
</template>
