<script setup lang="ts">
import { computed, type PropType, watch } from "vue";
import { useField } from "vee-validate";
import { IconName } from "@/types";
import Icon from "@/components/generic/Icon/Icon.component.vue";

const props = defineProps({
  fieldName: {
    type: String as PropType<string>,
    required: true,
  },
  signatureUrl: {
    type: String as PropType<string>,
    default: "",
  },
});

defineEmits(["click"]);

// eslint-disable-next-line vue/no-setup-props-destructure
const { errorMessage, meta, handleChange } = useField<string>(
  props.fieldName,
  undefined,
  { validateOnValueUpdate: false },
);

const signatureUrl = computed(() => props.signatureUrl);
watch(signatureUrl, (newValue) => handleChange(newValue));

const modifier = computed(() => ({ "has-error": !meta.valid && meta.dirty }));
</script>

<template>
  <div class="signature-button">
    <button
      class="signature-button--button"
      type="button"
      :class="modifier"
      @click="$emit('click')"
    >
      <img
        v-if="signatureUrl !== ''"
        class="signature-button--signature"
        :src="signatureUrl"
      />
      <div v-else class="signature-button--placeholder">
        <Icon :icon="IconName.Pen" :size="60" />
        {{ $t("components.signature.open") }}
      </div>
    </button>
    <span v-if="!meta.valid && meta.dirty" class="signature-button--error">
      {{ errorMessage }}
    </span>
  </div>
</template>

<style>
.signature-button {
  inline-size: 100%;
  block-size: 100%;
}

.signature-button--button {
  display: flex;
  justify-content: center;
  align-items: center;
  inline-size: 100%;
  block-size: 100%;
  border-radius: 4px;
  box-shadow: var(--elevation-13);
  background-color: var(--color-background-light);

  &.has-error {
    border: 1px solid var(--color-error);
  }

  &:active {
    background-color: none;
  }
}

.signature-button--placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-16);
  color: var(--color-background-transparent);
}

.signature-button--error {
  @apply text-14/24;

  color: var(--color-error);
}

.signature-button--signature {
  flex-shrink: 0;
  block-size: 100%;
}
</style>
