import { createPlausible } from "@/setup/plausible/createPlausible";

export const plausible = createPlausible({
  init: {
    domain: import.meta.env.VITE_PLAUSIBLE_DOMAIN,
  },
  settings: {
    enableAutoOutboundTracking: true,
    enableAutoPageviews: true,
  },
});
