<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path
      d="
        M16 4a12 12 0 1 0 0 24 12 12 0 0 0 0-24Zm0 2a10 10 0 1 1 0 20 10 10 0 0
        1 0-20Zm0 4a4 4 0 0 0-4 4h2c0-1.1.9-2 2-2a2 2 0 0 1 2 2c0 .8-.5 1.4-1.2
        1.7l-.4.1a2 2 0 0 0-1.4 2V19h2v-1.3l.4-.1C19 17 20 15.6 20 14a4 4 0 0
        0-4-4Zm-1 10v2h2v-2Z
      "
    />
  </svg>
</template>
