import type { BlanketOrder, BlanketOrderItem, Unit } from "@/types";
import type { BlanketOrderFragment } from "@/types/graphql";

const buildBlanketOrderItem = (
  items: BlanketOrderFragment["blanketOrderItems"],
): BlanketOrderItem[] =>
  items.map((item) => ({
    id: item.id,
    unit: item.unit as unknown as Unit, // WeightUnit Enum was added, stuff for another PR
    remainingWeight: item.remainingWeight,
    material: item.material || undefined,
  }));

export const buildBlanketOrder = (
  blanketOrder: BlanketOrderFragment,
): BlanketOrder => ({
  type: "blanketOrder",
  id: blanketOrder.id,
  name: blanketOrder.name,
  number: blanketOrder.number,
  customer: blanketOrder.customer || undefined,
  blanketOrderItems: buildBlanketOrderItem(blanketOrder.blanketOrderItems),
});
