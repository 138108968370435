import { ref } from "vue";

export const useSidebar = () => {
  const isSidebarOpen = ref(false);

  const openSidebar = () => {
    isSidebarOpen.value = true;
  };

  const closeSidebar = () => {
    isSidebarOpen.value = false;
  };

  return {
    isSidebarOpen,
    openSidebar,
    closeSidebar,
  };
};
