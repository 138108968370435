import {
  RouteName,
  type ExtendedOrderItem,
  type BlanketOrder,
  OrderState,
} from "@/types";
import { computed } from "vue";

export const useCardLinks = (entry: ExtendedOrderItem | BlanketOrder) => {
  const detailsLink = computed(() => {
    if (entry.type === "blanketOrder") {
      return {
        name: RouteName.SelectOrderDetails,
        params: { blanketOrderId: entry.id },
      };
    }
    if (entry.state === OrderState.Closed) return undefined;

    return {
      name: RouteName.OrderShow,
      params: { orderId: entry.orderId, itemId: entry.id },
    };
  });

  const deliveryNoteLink = computed(() => {
    if (entry.type !== "order") return undefined;

    return {
      name: RouteName.DeliveryNoteIndex,
      params: { orderId: entry.orderId, itemId: entry.id },
    };
  });

  return {
    detailsLink,
    deliveryNoteLink,
  };
};
