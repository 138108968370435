import { defineStore } from "pinia";
import { ref, type Ref } from "vue";
import { CombinedError } from "urql";
import { getExtendedWeighings, serializeRawOrder } from "@/helpers";
import { GetOrderDocument } from "@/types/graphql";
import type { ExtendedWeighing } from "@/types";
import { client } from "@/setup/graphql";

interface WeighingsStore {
  weighings: Array<ExtendedWeighing>;
  fetching: boolean;
  error: Ref<CombinedError | undefined>;
}

export const useWeighingsStore = defineStore("weighings", {
  state: (): WeighingsStore => ({
    weighings: [],
    fetching: false,
    error: ref(undefined),
  }),
  actions: {
    async fetchWeighings(orderId: string, orderItemId: string) {
      this.error = undefined;
      this.fetching = true;
      const { data, error: originalError } = await client.value
        .query(GetOrderDocument, {
          id: orderId,
          withWeighings: true,
        })
        .toPromise();

      this.fetching = false;
      this.error = originalError;

      if (!data?.order) {
        if (!this.error) {
          this.error = new CombinedError({ graphQLErrors: ["No Order given"] });
        }
        return;
      }

      const serializedOrder = serializeRawOrder(data.order);

      const orderItem = serializedOrder.items?.find(
        (item) => item.id === orderItemId,
      );

      if (!orderItem?.weighings) return;

      this.weighings = getExtendedWeighings(
        orderItem.weighings,
        serializedOrder,
        orderItemId,
      );
    },
  },
});
