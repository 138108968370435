<script setup lang="ts">
import type { PropType } from "vue";
import type { SkeletonVariant } from "./SkeletonElement.model";

const props = defineProps({
  boxHeight: {
    type: String,
    default: undefined,
  },
  variant: {
    type: String as PropType<SkeletonVariant>,
    required: true,
  },
  width: {
    type: String,
    default: "100%",
  },
});

// eslint-disable-next-line vue/no-setup-props-destructure
const boxStyles = {
  blockSize: props.boxHeight,
  inlineSize: props.width,
};
</script>

<template>
  <div class="skeleton--box" :style="boxStyles">
    <div class="skeleton--element" :class="`is-${variant}`" />
  </div>
</template>

<style>
.skeleton--box {
  display: flex;
  align-items: center;
}

.skeleton--element {
  inline-size: 100%;
  background-color: var(--color-background-neutral-shaded);
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;

  &.is-badge {
    block-size: 24px;
    border-radius: 2em;
  }

  &.is-button {
    block-size: 48px;
    border-radius: 4px;
  }

  &.is-text {
    block-size: 13px;
    border-radius: 2em;
  }
}
</style>
