import Plausible from "plausible-tracker";
import type { App } from "vue";
import type { PlausibleOptions } from "plausible-tracker";

// Adopted from https://github.com/huntersofbook/huntersofbook/tree/main/projects/plausible/packages/vue which had some malfunctions

interface OptionPlugin {
  /**
   * Plausible options
   * @type PlausibleOptions
   */
  init: PlausibleOptions;

  /**
   * Plausible Settings
   * @type InstallOptions
   */
  settings: InstallOptions;
}

interface InstallOptions {
  /**
   * Enables automatic page view tracking for SPAs
   * @default true
   * @see https://github.com/plausible/plausible-tracker
   * @type boolean
   */
  enableAutoPageviews?: boolean;
  /**
   * Outbound link click tracking
   * @default false
   * @see https://plausible.io/docs/outbound-link-click-tracking
   * @type boolean
   *
   */
  enableAutoOutboundTracking?: boolean;
}

export const createPlausible = (options: OptionPlugin) => {
  const plausiblePlugin = {
    install(app: App): void {
      const data = {
        apiHost: "https://plausible.io",
        ...options.init,
      } as OptionPlugin["init"];

      const settings = {
        enableAutoPageViews: true,
        ...options.settings,
      } as OptionPlugin["settings"];

      const plausible = Plausible(data);

      if (settings.enableAutoPageviews === true) {
        plausible.enableAutoPageviews();
      }

      if (settings.enableAutoOutboundTracking === true) {
        plausible.enableAutoOutboundTracking();
      }

      // eslint-disable-next-line no-param-reassign
      app.config.globalProperties.$plausible = plausible;
      app.provide("$plausible", plausible);
    },
  };

  return plausiblePlugin;
};
