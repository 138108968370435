<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path
      d="
        M16 3a3 3 0 0 0-2.1.9l-.2.1L4 13.8a3 3 0 0 0 0 4.3l10 10a3 3 0 0 0 4.2
        0l10-10a3 3 0 0 0 0-4.2l-10-10A3 3 0 0 0 16 3zm0 2c.3 0 .5 0 .7.3l10
        10c.4.4.4 1 0 1.4l-10 10a1 1 0 0 1-1.4 0l-10-10a1 1 0 0 1
        0-1.4l10-10c.2-.2.4-.3.7-.3zm1 6v3h-4a2 2 0 0 0-2 2v3h2v-3h4v3l4-4-4-4z
      "
    />
  </svg>
</template>
