<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 17">
    <path
      d="
      M8 .8a7.7 7.7 0 1 0 0 15.4A7.7 7.7 0 0 0 8 .8Zm6.1 4.7h-2.6a9.2 9.2 0 0 0-1.3-3.4 7 7 0 0 1 4
      3.4Zm-3.3 3-.1 2H5.5a13.2 13.2 0 0 1 0-4h5.2l.1 2ZM8.1 1.7c.8 0 1.9 1.5 2.4 3.8H5.6c.5-2.3
      1.6-3.8 2.5-3.8ZM6 2.1c-.6.8-1.1 2-1.4 3.4H2a7 7 0 0 1 4-3.4ZM1.3 8.5c0-.7.1-1.3.3-2h2.9a14 14
      0 0 0 0 4H1.6c-.2-.6-.3-1.3-.3-2Zm.7 3h2.6c.3 1.4.8 2.6 1.4 3.4a6.9 6.9 0 0 1-4-3.4Zm6 3.8c-.8
      0-1.9-1.4-2.4-3.8h5c-.6 2.4-1.7 3.8-2.5 3.8Zm2.2-.4c.6-.8 1-2 1.3-3.4h2.6a6.9 6.9 0 0 1-4
      3.4Zm1.5-4.4a13.3 13.3 0 0 0 0-4h2.8a6.9 6.9 0 0 1 0 4h-2.8Z
      "
    />
  </svg>
</template>
