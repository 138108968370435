<script setup lang="ts">
import { useLink, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { useForm } from "vee-validate";
import { storeToRefs } from "pinia";
import { computed, onMounted, ref, type PropType } from "vue";
import { PageLayout } from "@/layouts";
import {
  TitleHeader,
  Icon,
  PfrButton,
  FieldInput,
  ImageUpload,
  SignatureButton,
  SignatureField,
  DeliveryNoteDetailList,
  LoadingStateDisplay,
  PfrFieldset,
  PfrForm,
} from "@/components";
import {
  ButtonElement,
  type ConfirmationPhoto,
  IconName,
  LoadingState,
  RouteName,
  ToastStatus,
} from "@/types";
import { useOrderStore, useWeighingStore } from "@/stores";
import { getUniqueId, buildImageUrl } from "@/helpers";
import {
  useBackLink,
  useToasts,
  useWeighingRedirectToStep,
} from "@/composables";

const props = defineProps({
  orderId: {
    type: String as PropType<string>,
    required: true,
  },
  itemId: {
    type: String as PropType<string>,
    required: true,
  },
  weighingId: {
    type: String as PropType<string>,
    required: true,
  },
});

const { firstStepLink } = useBackLink();

const router = useRouter();

const erroredConfirmationPhotos = ref<ConfirmationPhoto[]>([]);
const weighingStore = useWeighingStore();
const orderStore = useOrderStore();
const { fetchWeighing } = weighingStore;
const {
  weighing,
  error: weighingError,
  fetching: weighingFetching,
} = storeToRefs(weighingStore);
const {
  order,
  error: orderError,
  fetching: orderFetching,
} = storeToRefs(orderStore);

const error = computed(() => weighingError.value || orderError.value);
const fetching = computed(() => weighingFetching.value || orderFetching.value);

onMounted(() => fetchWeighing(props.orderId, props.itemId, props.weighingId));

useWeighingRedirectToStep(weighing);

// as signature field resets if not visible we need to cache it here
const signatureBlob = ref<Blob>();
const isSignatureFieldOpen = ref(false);
const formId = getUniqueId("deliveryNoteForm");

const loadingState = computed(() => {
  if (error.value) {
    if (error.value.networkError) return LoadingState.NetworkError;
    return LoadingState.GraphqlError;
  }
  if (fetching.value) return LoadingState.Loading;
  return LoadingState.Success;
});

const closedDeliveryNoteLink = computed(() => ({
  name: RouteName.ClosedDeliveryNote,
  params: {
    weighingId: props.weighingId,
    orderId: props.orderId,
    itemId: props.itemId,
  },
}));

const onCancel = () => {
  if (isSignatureFieldOpen.value) {
    isSignatureFieldOpen.value = false;
  }
};

const { t } = useI18n();

const heading = computed(() =>
  isSignatureFieldOpen.value
    ? t("deliveryNote.signatureHeading")
    : t("deliveryNote.heading"),
);

interface DeliveryNoteFields {
  signatureName: string;
  signature: Blob;
  signatureUrl: string;
  confirmationPhotos: ConfirmationPhoto[];
}

const validationSchema = {
  signatureName: "required",
  signatureUrl: "required",
  confirmationPhotos: "maxFiles:10",
};

const { handleSubmit, values, setErrors, setValues, meta } =
  useForm<DeliveryNoteFields>({ validationSchema });

const { saveCustomersSignature, saveConfirmationPhotos } = useWeighingStore();

const { addToast } = useToasts();

const redirectWhenSuccessful = (successful: boolean) => {
  if (!successful) {
    addToast(t("request.error"), ToastStatus.Error);
    return;
  }

  router.push(closedDeliveryNoteLink.value);
};

const onSubmit = handleSubmit(async ({ signatureName, confirmationPhotos }) => {
  if (!weighing.value) return;

  if (!signatureBlob.value && confirmationPhotos.length === 0) {
    setErrors({
      signatureUrl: t(
        "deliveryNote.validation.oneOfConfirmationPhotoAndSignature",
      ),
      confirmationPhotos: t(
        "deliveryNote.validation.oneOfConfirmationPhotoAndSignature",
      ),
    });

    return;
  }

  let isSuccess: boolean = false;

  if (signatureBlob.value) {
    isSuccess = await saveCustomersSignature(
      signatureBlob.value,
      signatureName,
    );
  }

  erroredConfirmationPhotos.value =
    await saveConfirmationPhotos(confirmationPhotos);

  redirectWhenSuccessful(
    isSuccess && erroredConfirmationPhotos.value.length === 0,
  );
});

const onSaveConfirmationPhoto = async () => {
  erroredConfirmationPhotos.value = await saveConfirmationPhotos(
    erroredConfirmationPhotos.value,
  );

  redirectWhenSuccessful(erroredConfirmationPhotos.value.length === 0);
};

const onSaveSignature = () => {
  signatureBlob.value = values.signature;

  const newSignatureUrl = values.signature
    ? buildImageUrl(values.signature)
    : "";
  setValues({ signatureUrl: newSignatureUrl });

  isSignatureFieldOpen.value = false;
};

const { href } = useLink({
  to: firstStepLink({
    orderId: props.orderId,
    itemId: props.itemId,
    orderState: order.value?.state,
  }),
});
</script>

<template>
  <PageLayout>
    <template #header>
      <TitleHeader :heading="heading">
        <template v-if="isSignatureFieldOpen" #left>
          <button @click="onCancel">
            <Icon :icon="IconName.AngleLeft" :is-bold="true" />
          </button>
        </template>
        <template v-else #right>
          <pfreundt-button
            variant="text"
            color="primary"
            :href="href"
            button-type="link"
            size="xsmall"
          >
            {{ t("navigation.cancel") }}
          </pfreundt-button>
        </template>
      </TitleHeader>
    </template>
    <template #main>
      <LoadingStateDisplay :loading-state="loadingState">
        <template #default>
          <div v-if="weighing" class="delivery-note-view">
            <div
              class="delivery-note-view--delivery-note"
              :class="{ 'is-invisible': isSignatureFieldOpen }"
            >
              <div class="delivery-note-view--summary">
                <h1 class="delivery-note-view--section-headline">
                  {{
                    $t("deliveryNote.detailsHeading", {
                      value: weighing.bonNumber,
                    })
                  }}
                </h1>
                <DeliveryNoteDetailList :weighing="weighing" />
              </div>
              <PfrForm :id="formId" @submit-form="onSubmit">
                <template #default>
                  <PfrFieldset :heading="$t('deliveryNote.acceptedFrom')">
                    <template #default>
                      <FieldInput
                        field-name="signatureName"
                        :is-required="true"
                        :label="$t('deliveryNote.name')"
                      />
                    </template>
                  </PfrFieldset>

                  <PfrFieldset
                    :heading="$t('deliveryNote.signatureForConfirmation')"
                  >
                    <template #default>
                      <div class="delivery-note-view--signature-button">
                        <SignatureButton
                          field-name="signatureUrl"
                          :signature-url="values.signatureUrl"
                          @click="isSignatureFieldOpen = true"
                        />
                      </div>
                    </template>
                  </PfrFieldset>

                  <PfrFieldset :heading="$t('deliveryNote.confirmationPhotos')">
                    <div class="mb-40">
                      <ImageUpload
                        field-name="confirmationPhotos"
                        :is-required="true"
                      />
                    </div>
                  </PfrFieldset>
                </template>
              </PfrForm>
            </div>
            <div
              class="delivery-note-view--signature-field"
              :class="{ 'is-invisible': !isSignatureFieldOpen }"
            >
              <SignatureField field-name="signature" />
            </div>
          </div>
        </template>
      </LoadingStateDisplay>
    </template>
    <template #mainFooter>
      <PfrButton
        :is="ButtonElement.Button"
        v-if="!isSignatureFieldOpen && erroredConfirmationPhotos.length === 0"
        :disabled="!meta.valid"
        type="submit"
        :form="formId"
        :full-width="true"
      >
        {{ $t("deliveryNote.continue") }}
      </PfrButton>

      <PfrButton
        :is="ButtonElement.Button"
        v-else-if="isSignatureFieldOpen"
        :full-width="true"
        @click="onSaveSignature"
      >
        {{ $t("deliveryNote.save") }}
      </PfrButton>

      <PfrButton
        :is="ButtonElement.Button"
        v-else
        :full-width="true"
        @click="redirectWhenSuccessful(true)"
      >
        {{ $t("deliveryNote.continue") }}
      </PfrButton>

      <PfrButton
        :is="ButtonElement.Button"
        v-if="erroredConfirmationPhotos.length > 0"
        :full-width="true"
        @click="onSaveConfirmationPhoto"
      >
        {{ $t("deliveryNote.retryConfirmationPhotos") }}
      </PfrButton>
    </template>
  </PageLayout>
</template>

<style>
.delivery-note-view--delivery-note {
  &.is-invisible {
    display: none;
  }
}

.delivery-note-view--summary {
  margin-block-end: var(--spacing-24);
  border-block-end: var(--color-border-near-light) 1px solid;
  padding-block-end: var(--spacing-24);
}

.delivery-note-view--section-headline {
  margin-block-end: var(--spacing-16);
  font-weight: 700;
}

.delivery-note-view--signature-button {
  block-size: var(--signature-button-height);
}

.delivery-note-view--signature-field {
  block-size: var(--signature-field-height);
  margin: auto;
  margin-block: var(--spacing-40);

  &.is-invisible {
    display: none;
  }
}
</style>
