<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path
      d="
        m16 3.2-.9 1.5-12 20.8-.8 1.5h27.4l-.8-1.5-12-20.8Zm0 4L26.3
        25H5.6ZM15 14v6h2v-6Zm0 7v2h2v-2Z
      "
    />
  </svg>
</template>
