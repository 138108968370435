<script setup lang="ts">
import { type PropType, computed } from "vue";
import { useI18n } from "vue-i18n";
import { IconName, IconSize, BadgeStatus, Unit, ScaleStatus } from "@/types";
import { useFormatUnit } from "@/helpers";
import ScaleArc from "@/components/generic/ScaleArc/ScaleArc.component.vue";
import Badge from "@/components/generic/Badge/Badge.component.vue";
import IconBadge from "@/components/generic/IconBadge/IconBadge.component.vue";
import { scaleToBadgeStatus } from "./scaleToBadgeStatus";

const { t } = useI18n();

const props = defineProps({
  targetWeight: {
    type: Number as PropType<number>,
    required: true,
  },
  targetWeightUnit: {
    type: String as PropType<Unit>,
    required: true,
  },
  currentWeight: {
    type: Number as PropType<number>,
    required: true,
  },
  currentWeightUnit: {
    type: String as PropType<Unit>,
    required: true,
  },
  orderNumber: {
    type: String as PropType<String>,
    required: true,
  },
  material: {
    type: String as PropType<String>,
    required: true,
  },
  status: {
    type: String as PropType<ScaleStatus>,
    required: true,
  },
});

const { formatUnit } = useFormatUnit();

const targetWeightLabel = computed(() =>
  t("scale.targetWeight", {
    weight: formatUnit(props.targetWeight, props.targetWeightUnit),
    material: props.material,
  }),
);

const icon = computed(() => {
  switch (props.status) {
    case ScaleStatus.Pending:
      return IconName.Pause;
    case ScaleStatus.Loading:
      return IconName.Play;
    case ScaleStatus.VehicleReady:
    case ScaleStatus.OrderReady:
      return IconName.Check;
    case ScaleStatus.VehicleOverloaded:
    case ScaleStatus.OrderOverloaded:
      return IconName.Exclamation;
    default:
      return IconName.Pause;
  }
});
</script>

<template>
  <div class="scale-card">
    <div class="scale-card--icon">
      <IconBadge
        :status="scaleToBadgeStatus(status)"
        :size="IconSize.Medium"
        :icon="icon"
      />
    </div>

    <h1 class="scale-card--status-text" :class="`is-${status}`">
      {{ t(`scale.status.${status}`) }}
    </h1>
    <h2 class="scale-card--order-text">
      {{ $t("order.heading", { id: orderNumber }) }}
    </h2>

    <div class="scale-card--scale">
      <ScaleArc
        :current-weight="currentWeight"
        :current-weight-unit="currentWeightUnit"
        :target-weight="targetWeight"
        :target-weight-unit="targetWeightUnit"
        :scale-status="status"
      />
    </div>
    <Badge :status="BadgeStatus.Neutral" :label="targetWeightLabel" />
  </div>
</template>

<style>
.scale-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-block-start: var(--spacing-24);
  border-radius: 4px;
  padding-block: var(--spacing-40) var(--spacing-24);
  padding-inline: var(--spacing-40);
  box-shadow: var(--elevation-13);
  background-color: var(--color-background-light);
}

.scale-card--icon {
  position: absolute;
  inset-block-start: 0;
  transform: translateY(-50%);
}

.scale-card--scale {
  inline-size: var(--scale-arc-width);
  margin-block-end: var(--spacing-40);
}

.scale-card--status-text {
  @apply text-18/27;

  margin-block-end: var(--spacing-8);
  font-weight: 700;

  &.is-pending {
    color: var(--color-neutral);
  }

  &.is-loading {
    color: var(--color-info);
  }

  &.is-vehicleReady {
    color: var(--color-success);
  }

  &.is-orderReady {
    color: var(--color-success);
  }

  &.is-vehicleOverloaded {
    color: var(--color-error);
  }

  &.is-orderOverloaded {
    color: var(--color-error);
  }
}

.scale-card--order-text {
  @apply text-16/24;

  margin-block-end: var(--spacing-40);
}
</style>
