<script lang="ts" setup>
import {
  OrderIndexMain,
  SkeletonOrderIndex,
  LoadingStateDisplay,
} from "@/components";
import { LoadingState } from "@/types";
import { PageLayout } from "@/layouts";
import { useI18n } from "vue-i18n";
import { onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useCombinedOrdersStore } from "@/stores";

const combinedOrdersStore = useCombinedOrdersStore();
const { fetchCombinedOrders } = combinedOrdersStore;
const { combinedOrders, fetching } = storeToRefs(combinedOrdersStore);

const { t } = useI18n();

onMounted(() => {
  fetchCombinedOrders("");
});

const search = (searchValue: string) => {
  fetchCombinedOrders(searchValue);
};
</script>

<template>
  <PageLayout :heading="t('views.openOrders.heading')" @search="search">
    <template #main>
      <LoadingStateDisplay
        :loading-state="fetching ? LoadingState.Loading : LoadingState.Success"
      >
        <template #loading>
          <SkeletonOrderIndex />
        </template>

        <template #default>
          <OrderIndexMain
            v-if="combinedOrders"
            :combined-orders="combinedOrders"
          />
        </template>
      </LoadingStateDisplay>
    </template>
  </PageLayout>
</template>
