export default {
  currentWeight: "Current Weight",
  status: {
    pending: "Wait for loading",
    loading: "Loading in progress",
    vehicleReady: "Vehicle capacity reached",
    orderReady: "Order target weight reached",
    vehicleOverloaded: "Vehicle capacity exceeded",
    orderOverloaded: "Order target weight overloaded",
  },
  targetWeight: "of {weight} {material}",
};
