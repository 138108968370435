import { useI18n } from "vue-i18n";
import { Unit } from "@/types";

const UNIT_CONVERSIONS = {
  [Unit.g]: 1,
  [Unit.kg]: 1_000,
  [Unit.t]: 1_000_000,
  [Unit.pd]: 14.087,
  [Unit.lb]: 453.592,
  [Unit.st]: 907_185,
  [Unit.lt]: 1_016_046.9,
  [Unit.dwt]: 1.555,
  [Unit.oz]: 28.35,
};

export const convertUnit = (value: number, unit: Unit, targetUnit: Unit) =>
  (value * UNIT_CONVERSIONS[unit]) / UNIT_CONVERSIONS[targetUnit];

export const useFormatUnit = () => {
  const { t, n } = useI18n();

  const formatUnit = (value: number, unit: Unit, targetUnit?: Unit) => {
    const converted = targetUnit ? convertUnit(value, unit, targetUnit) : value;
    const translationKey = `units.${targetUnit || unit}`;

    if (!Number.isInteger(converted)) {
      return t(translationKey, { value: n(converted, "decimal") });
    }

    return t(translationKey, { value: n(converted) });
  };

  return {
    formatUnit,
  };
};
