<script setup lang="ts">
import { computed, type PropType } from "vue";
import type { RouteLocationRaw } from "vue-router";
import {
  IconName,
  IconSize,
  ButtonVariant,
  ButtonSize,
  ButtonElement,
} from "@/types";
import Icon from "../Icon/Icon.component.vue";

const props = defineProps({
  // Common
  fullWidth: {
    type: Boolean,
    default: false,
  },
  is: {
    type: String as PropType<ButtonElement>,
    required: true,
  },
  icon: {
    type: String as PropType<IconName>,
    default: "",
  },
  size: {
    type: String as PropType<ButtonSize>,
    default: ButtonSize.Medium,
  },
  variant: {
    type: String as PropType<ButtonVariant>,
    default: ButtonVariant.Primary,
  },
  // Button
  disabled: {
    type: Boolean as PropType<boolean>,
    default: undefined,
  },
  type: {
    type: String as PropType<string>,
    default: "button",
  },
  form: {
    type: String as PropType<string>,
    default: undefined,
  },
  // Link
  href: {
    type: String as PropType<string>,
    default: undefined,
  },
  target: {
    type: String as PropType<string>,
    default: undefined,
  },
  // RouterLink
  to: {
    type: [String, Object] as PropType<RouteLocationRaw>,
    default: undefined,
  },
});

const elementProps = computed(() => {
  switch (props.is) {
    case ButtonElement.Link:
      return {
        href: props.href,
        target: props.target,
      };
    case ButtonElement.RouterLink:
      return {
        to: props.to,
      };
    default:
      return {
        type: props.type,
        disabled: props.disabled,
        form: props.form,
      };
  }
});

const iconSize = computed(() =>
  props.size === ButtonSize.Small ? IconSize.Tiny : IconSize.Small,
);
</script>

<template>
  <component
    :is="is"
    v-bind="elementProps"
    :class="[
      'button',
      'antialiased',
      `is-${variant}`,
      `is-${size}`,
      { 'is-full-width': fullWidth },
    ]"
  >
    <Icon v-if="icon" :icon="icon" :size="iconSize" />
    <slot />
  </component>
</template>

<style>
.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-8);
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  padding-inline: var(--spacing-16);

  /* States */
  &:focus,
  &:hover,
  &:active {
    text-decoration: none;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.is-full-width {
    inline-size: 100%;
  }

  /* Variants */
  &.is-primary {
    border-color: var(--color-primary);
    box-shadow: var(--elevation-5);
    font-weight: 700;
    color: var(--color-text-light);
    background-color: var(--color-primary);

    &:not(button:disabled):where(:focus, :hover, :active) {
      border-color: var(--color-border-obtrusive);
      box-shadow: var(--elevation-13);
      background-color: var(--color-background-obtrusive);
    }
  }

  &.is-secondary {
    border-color: var(--color-primary);
    box-shadow: var(--elevation-5);
    color: var(--color-primary);
    background-color: var(--color-background-light);

    &:not(button:disabled):where(:focus, :hover, :active) {
      box-shadow: var(--elevation-13);
      background-color: var(--color-background-unobtrusive);
    }
  }

  &.is-plain {
    border-color: transparent;
    color: var(--color-primary);

    &:not(button:disabled):where(:focus, :hover, :active) {
      text-decoration: underline;
    }
  }

  /* Sizes */
  &.is-large {
    @apply text-18/24;

    padding-block: 11px; /* arbitrary value to match designs */
  }

  &.is-medium {
    @apply text-18/24;

    padding-block: 9px; /* arbitrary value to match designs */
  }

  &.is-small {
    @apply text-16/20;

    padding-block: var(--spacing-8);
  }
}
</style>
